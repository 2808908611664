import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { IUser } from 'src/types';

const userSlice = createSlice<IUser | null, SliceCaseReducers<IUser | null>>({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, { payload }: PayloadAction<IUser | null>) => {
      // if (state) {
      //   return {
      //     ...state,
      //     ...payload,
      //   };
      // }
      return payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
