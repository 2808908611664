import { IBaseResponse, IParams, IRegisterBody, IUpdateUserBody, IUser, IUserBody } from 'src/types';
import { http } from 'src/utils';
import BaseService from './base.service';

export default class UserService extends BaseService<IUser, IUserBody, IUserBody> {
  constructor() {
    super('/users');
  }

  public async me(query: IParams) {
    const { data } = await http.get<IBaseResponse<IUser>>(`${this.url}/me`, { params: query });
    return data.data;
  }

  public async updateCurrent(body: IUpdateUserBody) {
    const { data } = await http.patch<IBaseResponse<IUser>>(`${this.url}/me`, body);
    return data.data;
  }

  public async register(body: IRegisterBody) {
    const { data } = await http.post<IBaseResponse<any>>(`${this.url}/register`, body);
    return data.data;
  }

  public async verify(query: { token: string }) {
    const { data } = await http.get<IBaseResponse<any>>(`${this.url}/register/verify-email`, { params: query });
    return data.data;
  }
}
