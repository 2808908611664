import { EnvironmentOutlined } from '@ant-design/icons';
import { FormInstance, Popover, Row, Tooltip } from 'antd';
import { EPropertyCategoryType, IPropertyCategory } from 'src/types';
import BaseMap from '../BaseMap';
import { BaseColorPicker, BaseDatePicker, BaseInput, BaseInputNumber, BaseTextArea } from '../form';

export const renderInput = (propertyCategory: IPropertyCategory, form: FormInstance<any>, value?: string) => {
  const nameValue = value ? `value.${value}.${propertyCategory.id}` : `value.${propertyCategory.id}`;
  const nameLabel = value ? `label.${value}.${propertyCategory.id}` : `label.${propertyCategory.id}`;

  switch (propertyCategory.type) {
    case EPropertyCategoryType.STRING:
      return (
        <Row gutter={12}>
          <BaseInput name={nameValue} span={12} placeholder="Giá trị" noMessage size="small" />
          <BaseInput name={nameLabel} span={12} placeholder="Nhãn" noMessage size="small" />
        </Row>
      );
    case EPropertyCategoryType.NUMBER:
      return (
        <Row gutter={12}>
          <BaseInputNumber name={nameValue} span={12} placeholder="Giá trị" noMessage size="small" />
          <BaseInput name={nameLabel} span={12} placeholder="Nhãn" noMessage size="small" />
        </Row>
      );
    case EPropertyCategoryType.COLOR:
      return (
        <Row gutter={12}>
          <BaseColorPicker name={nameValue} size="small" span={2} noMessage />
          <BaseInput name={nameLabel} span={16} placeholder="Nhãn" noMessage size="small" />
        </Row>
      );
    case EPropertyCategoryType.TEXT:
      return (
        <Row gutter={12}>
          <BaseTextArea name={nameValue} size="small" span={24} noMessage rows={2} showCount={false} />
        </Row>
      );
    case EPropertyCategoryType.TIME:
      return (
        <Row gutter={12}>
          <BaseDatePicker name={nameValue} span={12} placeholder="Giá trị" noMessage size="small" />
          <BaseInput name={nameLabel} span={12} placeholder="Nhãn" noMessage size="small" />
        </Row>
      );
    case EPropertyCategoryType.LOCATION:
      // eslint-disable-next-line no-case-declarations
      const [lat, lng] = form.getFieldValue(nameValue)
        ? form.getFieldValue(nameValue).split(', ')
        : [undefined, undefined];
      return (
        <Row gutter={12}>
          <Popover
            content={
              <BaseMap
                onChange={(lat, lng) => {
                  if (!lat || !lng) return;

                  form.setFieldsValue({
                    [nameValue]: `${lat?.toFixed(4)}, ${lng?.toFixed(4)}`,
                  });
                }}
                data={lat && lng ? { lat: Number(lat), lng: Number(lng) } : undefined}
              />
            }
            trigger="click"
          >
            <Tooltip title="Vị trí">
              <EnvironmentOutlined style={{ margin: '0px 8px' }} />
            </Tooltip>
          </Popover>
          <BaseInput name={nameValue} span={8} placeholder="Toạ độ" noMessage size="small" readOnly />
          <BaseInput name={nameLabel} span={12} placeholder="Nhãn" noMessage size="small" />
        </Row>
      );

    default:
      break;
  }

  return null;
};
