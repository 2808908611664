import { FilterOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Form, Tooltip } from 'antd';
import { useEffect } from 'react';
import { BaseInput } from 'src/libraries';
import { useTenant } from 'src/stores';
import { ISetting } from 'src/types';

interface IProps {
  onSearch: (values: any) => void;
  onRefresh: () => void;
  onFilter: () => void;
  countFilter: number;
  setting?: ISetting;
}

export function FormSearch(props: IProps) {
  const [form] = Form.useForm();
  const { colorBold } = useTenant();

  useEffect(() => {
    form.resetFields();
  }, [props.setting]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onSearch(e?.target?.value);
  };

  const onRefresh = () => {
    form.resetFields();
    props.onRefresh();
  };

  return (
    <Form form={form}>
      <Button.Group>
        <Button type="primary" size="large" icon={<SearchOutlined />} />
        <BaseInput
          name="search"
          placeholder="Tìm kiếm..."
          span={16}
          noMessage
          formItemClassName="input-search"
          onChange={onSearch}
        />
        <Tooltip title="Bộ lọc" placement="bottom">
          <Badge count={props.countFilter} color={colorBold}>
            <Button type="primary" onClick={props.onFilter} size="large" icon={<FilterOutlined />} />
          </Badge>
        </Tooltip>
        <Tooltip title="Làm mới" placement="bottom">
          <Button size="large" icon={<ReloadOutlined />} onClick={onRefresh} />
        </Tooltip>
      </Button.Group>
    </Form>
  );
}
