import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useMemo } from 'react';
import { CONTANTS, REGEX } from 'src/utils';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof Input>, IBaseFormItemProps {
  showSearch?: boolean;
  noSpecial?: boolean;
  isPhone?: boolean;
  isEmail?: boolean;
  isPassword?: boolean;
}

export function BaseInput(props: IProps) {
  const rules = useMemo(() => {
    return getRules(props);
  }, [props]);

  const placeholder = useMemo(() => {
    return getPlaceholder(props);
  }, [props]);

  const suffix = useMemo(() => {
    return getSuffix(props);
  }, [props]);

  return (
    <BaseFormItem {...props} rules={rules}>
      {!props.isPassword ? (
        <Input {...props} allowClear size={props.size || 'large'} placeholder={placeholder} suffix={suffix} />
      ) : (
        <Input.Password {...props} allowClear size={props.size || 'large'} placeholder={placeholder} suffix={suffix} />
      )}
    </BaseFormItem>
  );
}

const getSuffix = (props: IProps) => {
  if (props.showSearch) {
    return <SearchOutlined style={{ color: '#999' }} />;
  }

  return props.suffix;
};

const getPlaceholder = (props: IProps) => {
  if (props.placeholder) {
    return props.placeholder;
  }

  return props.label;
};

const getRules = (props: IProps) => {
  if (props.required) {
    let rules: Rule[] = [];

    rules.push({
      whitespace: true,
      required: true,
      message: `${props.label || props.placeholder} không được để trống`,
    });

    if (props.noSpecial) {
      rules.push({
        validator: (_, value) => {
          if (REGEX.isSpecialCharacter(value) || REGEX.isEmoji(value)) {
            return Promise.reject(new Error(`${props.label || props.placeholder} không được chứa ký tự đặc biệt`));
          }
          return Promise.resolve();
        },
      });
    }

    if (props.isPhone) {
      rules.push({
        pattern: new RegExp(CONTANTS.REGEX_PHONE),
        message: `${props.label || props.placeholder} không đúng định dạng`,
      });
    }

    if (props.isEmail) {
      rules.push({
        pattern: new RegExp(CONTANTS.REGEX_EMAIL),
        message: `${props.label || props.placeholder} không đúng định dạng`,
      });
    }

    // if (props.isPassword) {
    //   rules.push({
    //     pattern: new RegExp(REGEX_PASSWORD),
    //     message: `${props.label} không đúng định dạng`,
    //   });
    // }

    if (props.rules) {
      rules = [...rules, ...props.rules];
    }

    return rules;
  }

  return undefined;
};
