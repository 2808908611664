import { IBaseResponse, IParams, ISubject } from 'src/types';
import { http } from 'src/utils';
import BaseService from './base.service';

export default class SubjectService extends BaseService<ISubject> {
  constructor() {
    super('/subject');
  }

  public async get(query: IParams) {
    const { data } = await http.get<IBaseResponse<ISubject>>(`/items${this.url}`, {
      params: {
        ...query,
        fields: ['*', 'departments.department_id.*', 'departments.department_id.parents.*'], // Thêm trường này vào truy vấn
      },
    });
    return data.data;
  }

  public async updateSubject(obj: any, id: string) {
    const { data } = await http.patch<ISubject>(`/items${this.url}/${id}`, obj);
    return data;
  }

  public async createSubject(obj: ISubject) {
    const { data } = await http.post<ISubject>(`/items${this.url}`, obj);
    return data;
  }

  public async deleteSubject(id: string) {
    const { data } = await http.delete<ISubject>(`/items${this.url}/${id}`);
    return data;
  }
}
