import { I18n } from 'i18n-js';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { setLang } from './lang.slice';

export default function useLang() {
  const dispatch = useDispatch();
  const { lang, locale } = useAppSelector((state) => state.lang);

  const changeLocale = (locale: string) => {
    lang.locale = locale;
    dispatch(setLang({ locale, lang }));
  };

  const setupLang = (i18n: I18n) => {
    dispatch(setLang({ locale: i18n.locale, lang: i18n }));
  };

  return {
    locale,
    lang,
    // changeLayout,
    setupLang,
    changeLocale,
  };
}
