import { IBaseEntity, IRelationBody } from './common.type';
import { IProductEntityHistory, IProductEntityHistoryBody } from './product_entity_history.type';

export interface IProductEntity extends IBaseEntity {
  serial?: string;
  product_id: string;
  cost: number;
  tax: number;
  cost_final: number;
  cost_avg: number;
  shelf_id: string;
  tenant_id: string;
  status: EProductEntityStatus;
  product_entity_histories: IProductEntityHistory[];
}

export interface IProductEntityBody {
  serial?: string;
  product_id?: string;
  cost?: number;
  tax?: number;
  cost_final?: number;
  cost_avg?: number;
  shelf_id?: string;
  tenant_id?: string;
  status?: EProductEntityStatus;
  product_entity_histories?: IRelationBody<IProductEntityHistoryBody>;
}

export enum EProductEntityStatus {
  STORED = 1,
  SELLING = 2,
  SHIPPING = 3,
  SOLD = 4,
  RETURNING = 5,
  RETURNED = 6,
  TURNBACKING = 7,
}
