import { IBaseEntity } from '.';

export interface IEmotion extends IBaseEntity {
  type: EmotionType;
  news_id: string;
  comment_id: string;
}

export interface IEmotionBody extends IBaseEntity {
  type: EmotionType;
  news_id: string;
  comment_id: string;
}

export enum EmotionType {
  LIKE = 'LIKE',
  LOVE = 'LOVE',
  LAUGH = 'LAUGH',
  ANGRY = 'ANGRY',
  SAD = 'SAD',
  WOW = 'WOW',
}
