import { IBaseEntity, IBaseQuery } from './common.type';
import { IProductCategoryPropertyCategory } from './product_category-property_category.type';
import { IProperty } from './property.type';

export interface IPropertyCategory extends IBaseEntity {
  tenant_id: string;
  name: string;
  unit_id: string;
  type: EPropertyCategoryType;
  span: number;
  show: boolean;
  field: any;
  icon: string;
  image: string;
  product_categories: IProductCategoryPropertyCategory[];
  property_category_id: IPropertyCategory;
  children: IPropertyCategory[];
  select_type: EPropertyCategorySelectType;
  properties: IProperty[];
  required: any;
  settings: {
    priority: number;
    span: number;
    property_category_id: string;
    id: number;
    setting_id: string;
  }[];
  max: number;
}

export interface IPropertyCategoryBody {
  tenant_id: string;
  max?: number;
  name: string;
  unit_id?: string;
  type?: EPropertyCategoryType;
  span?: number;
  show?: boolean;
  icon?: string;
  image?: string;
  select_type?: EPropertyCategorySelectType;
}

export interface IPropertyCategoryQuery extends IBaseQuery {
  name?: string;
}

export interface ICreatePropertyCategoryBody extends IPropertyCategoryBody {}
export interface IUpdatePropertyCategoryBody extends IPropertyCategoryBody {}

export enum EPropertyCategoryType {
  STRING = 'STRING',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  TIME = 'TIME',
  COLOR = 'COLOR',
  LOCATION = 'LOCATION',
}

export enum EPropertyCategorySelectType {
  COMBO = 'COMBO',
  FREE = 'FREE',
  BOOL = 'BOOL',
}
