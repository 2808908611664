import React, { useRef } from 'react';

import { IBaseProps } from 'src/types';

interface IProps extends IBaseProps {
  accept?: string;
  multiple?: boolean;
  onUpload: React.ChangeEventHandler<HTMLInputElement>;
}

export function BaseUploadHidden(props: IProps) {
  const inputFile = useRef<HTMLInputElement>(null);

  return (
    <>
      {React.cloneElement(props.children, { onClick: () => inputFile.current?.click() })}
      <input
        type="file"
        id="upload"
        onChange={props.onUpload}
        className="hidden"
        accept={props.accept}
        ref={inputFile}
        multiple={props.multiple}
      />
    </>
  );
}
