import { Table } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { memo, useEffect, useMemo, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { Resizable } from 'react-resizable';
import { IFieldBody } from 'src/types';

interface IProps {
  data: any[];
  columns: any;
  loading: boolean;
  rowKey?: string;
  rowSelection?: TableRowSelection<any>;
  onRow?: any;
  onResizeField: (field: string, width: number) => void;
  onDragField: (fields: IFieldBody[]) => void;
  colsShow?: string[];
}

function ResizableTitle(props: any) {
  const { onResize, width, name, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      onResizeStop={() => props.onResizeField(name, width)}
    >
      <th {...restProps} />
    </Resizable>
  );
}

function BaseTable(props: IProps) {
  const [columns, setColumns] = useState<any>([]);
  const { innerHeight: height } = window;

  useEffect(() => {
    setColumns(
      props.columns.map((i: any) => {
        if (i.isFix) {
          return i;
        }
        return { ...i, title: <span className="dragHandler">{i.title as any}</span> };
      }),
    );
  }, [props.columns]);

  const dragProps = useMemo(() => {
    return {
      onDragEnd(fromIndex: number, toIndex: number) {
        const editColumns = [...columns];
        const item = editColumns.splice(fromIndex - 1, 1)[0];
        editColumns.splice(toIndex - 1, 0, item);

        props.onDragField(
          editColumns
            .filter((i) => !['stt', 'action'].includes(i.key as string))
            .map((i) => ({ field: i.key as string, width: i.width as number })),
        );

        setColumns(editColumns);
      },
      nodeSelector: '.dragable',
      handleSelector: '.dragHandler',
      ignoreSelector: 'react-resizable-handle',
    };
  }, [columns]);

  const handleResize =
    (index: number) =>
    (e: any, { size }: any) => {
      setColumns((prev: any) => {
        const nextColumns = [...prev];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const tableColumns = useMemo<any[]>(() => {
    return columns
      .filter((i: any) => {
        if (props.colsShow && !i.isFix) {
          return props.colsShow.includes(i.key as string);
        }
        return true;
      })
      .map((col: any, index: number) => ({
        ...col,
        onHeaderCell: (column: any) => {
          if (column.isFix) return;

          return {
            width: column.width,
            name: column.key,
            onResize: handleResize(index),
            onResizeField: props.onResizeField,
          };
        },
      }));
  }, [columns, props.colsShow]);

  return (
    <div style={{ minHeight: height - 184, overflow: 'scroll' }}>
      <ReactDragListView.DragColumn {...dragProps}>
        <Table
          className="table-custom"
          style={{ marginTop: 16 }}
          dataSource={props.data}
          columns={tableColumns}
          loading={props.loading}
          pagination={false}
          rowKey={props.rowKey || 'id'}
          size="middle"
          rowSelection={props.rowSelection}
          onRow={props.onRow}
          components={{
            header: {
              cell: ResizableTitle,
            },
          }}
          expandable={{
            showExpandColumn: false,
          }}
        />
      </ReactDragListView.DragColumn>
    </div>
  );
}

export default memo(BaseTable);
