import { CheckOutlined, FileImageOutlined } from '@ant-design/icons';
import { FormInstance, Image, Row, Space, Tooltip } from 'antd';
import { memo, useState } from 'react';
import { FileService } from 'src/services';
import { useTenant } from 'src/stores';
import { EPropertyCategorySelectType, IPropertyCategory } from 'src/types';
import { FUNCS } from 'src/utils';
import { BaseUploadHidden } from '../form';
import { IPropertyValue } from './PropertyInput';
import PropertySelect from './PropertySelect';
import { renderInput } from './renderInput';

interface IProps {
  propertyCategory: IPropertyCategory;
  propertyOpts: IPropertyValue[];
  onAddProperty: (data: { image?: string; icon?: string }) => void;
  onDeleteProperty: (id: string) => void;
  form: FormInstance<any>;
  onSubmit: (state: { image?: string; icon?: string }) => void;
}

const svFile = new FileService();

function PropertyAdd(props: IProps) {
  const { tenant, colorLight } = useTenant();
  const [state, setState] = useState<{ image?: string; icon?: string }>({});

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;

    const file = e.target.files[0];
    try {
      const res = await svFile.upload(file, {});
      setState((prev) => ({ ...prev, image: res.id }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="property" align="middle" style={{ backgroundColor: colorLight }}>
      <div style={{ flex: 1 }}>
        {props.propertyCategory.select_type === EPropertyCategorySelectType.COMBO ? (
          <PropertySelect
            name={props.propertyCategory.id}
            propertyCategory={props.propertyCategory}
            opts={props.propertyOpts}
            onAddProperty={props.onAddProperty}
            onDelete={props.onDeleteProperty}
            form={props.form}
          />
        ) : (
          <Row align="middle">
            <Tooltip title="Ảnh thuộc tính">
              <BaseUploadHidden onUpload={onUpload} accept="image/*">
                {state.image ? (
                  <Image
                    src={FUNCS.getFullMedialUrl(state.image)}
                    width={24}
                    height={24}
                    preview={false}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <FileImageOutlined style={{ color: tenant?.color, cursor: 'pointer' }} />
                )}
              </BaseUploadHidden>
            </Tooltip>

            <div style={{ flex: 1, marginLeft: 8 }}>{renderInput(props.propertyCategory, props.form)}</div>
          </Row>
        )}
      </div>
      <Space style={{ marginLeft: 20 }}>
        <Tooltip title="Lưu">
          <CheckOutlined className="property-btn" onClick={() => props.onSubmit(state)} />
        </Tooltip>
      </Space>
    </Row>
  );
}

export default memo(PropertyAdd);
