import { ColorPicker } from 'antd';
import React from 'react';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof ColorPicker>, IBaseFormItemProps {}

export function BaseColorPicker(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <ColorPicker {...props} format="hex" size={props.size || 'large'} />
    </BaseFormItem>
  );
}
