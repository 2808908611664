import { AutoComplete } from 'antd';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof AutoComplete>, IBaseFormItemProps {}

export function BaseAutoComplete(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <AutoComplete
        {...props}
        size={props.size || 'large'}
        placeholder={props.placeholder || props.label}
        style={{ width: '100%' }}
        className={`autocomplete-${props.size || 'large'}`}
      />
    </BaseFormItem>
  );
}
