import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { memo, useState } from 'react';
import { BaseInput } from 'src/libraries';
import { AuthService } from 'src/services';
import FlowService from 'src/services/flow.service';
import { useRefresh, useTenant, useUser } from 'src/stores';
import { EService, IBaseProps } from 'src/types';
import { CONTANTS, MESSAGE } from 'src/utils';
import { IService, serviceCategories, services, tenantTypes } from './contant';
import styles from './tenant.module.scss';

interface IProps extends IBaseProps {}

interface IState {
  loading: boolean;
  step: number;
  tenantType: number;
  services: EService[];
}

const initState: IState = {
  loading: false,
  step: 1,
  tenantType: 0,
  services: tenantTypes[0].services,
};

const svFlow = new FlowService();
const svAuth = new AuthService();
// const svTenant = new ItemService<ITenant>(ECollection.tenant);

function RegisterTenantScreen(props: IProps) {
  const [form] = Form.useForm();
  const { user } = useUser();
  const { tenant } = useTenant();
  const { changeRefresh, refresh } = useRefresh();
  const [state, setState] = useState<IState>(initState);

  const onCheckService = (service: IService, active: boolean) => {
    if (active) {
      setState((prev) => ({ ...prev, services: prev.services.filter((i) => i !== service.service) }));
    } else {
      setState((prev) => ({ ...prev, services: [...prev.services, service.service] }));
    }
  };

  const onChaneTenantType = (type: number) => {
    const serviceType = tenantTypes.find((i, index) => index === type);
    if (serviceType) {
      setState((prev) => ({ ...prev, tenantType: type, services: serviceType.services }));
    }
  };

  const onSubmit = async () => {
    if (state.loading) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      await svFlow.createTenant({
        name: form.getFieldValue('name'),
        phone: form.getFieldValue('phone'),
        services: state.services,
      });

      MESSAGE.success('Hoàn tất cài đặt cơ sở thành công!');

      // refresh token
      const refreshtoken = localStorage.getItem('refreshtoken');
      if (refreshtoken) {
        const res = await svAuth.refresh({ refresh_token: refreshtoken, mode: 'json' });
        localStorage.setItem('accesstoken', res.access_token);
        localStorage.setItem('refreshtoken', res.refresh_token);
        localStorage.setItem('expire', dayjs().add(res.expires, 'milliseconds').format(CONTANTS.DATE_TIME_SERVER));
      }

      changeRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  if (!user) return null;

  if (!tenant) {
    return (
      <div className={styles.registerTenantWrapper}>
        <Row align="middle">
          <Col span={11}>
            <Form
              form={form}
              layout="vertical"
              className={state.step === 1 ? '' : 'hidden'}
              onFinish={() => setState((prev) => ({ ...prev, step: 2 }))}
            >
              <Typography.Title>Thông tin doanh nghiệp của bạn</Typography.Title>
              <BaseInput placeholder="Tên cơ sở" name="name" span={24} required />
              <BaseInput placeholder="Số điện thoại" name="phone" span={24} required isPhone />
              <Row justify="end">
                <Button type="primary" htmlType="submit" icon={<ArrowRightOutlined />}>
                  Tiếp theo
                </Button>
              </Row>
            </Form>

            <div className={state.step === 2 ? '' : 'hidden'}>
              <Typography.Title>Chọn lĩnh vực kinh doanh của bạn</Typography.Title>

              <Row gutter={12}>
                {tenantTypes.map((i, index) => (
                  <Col span={6} key={index}>
                    <div
                      className={
                        state.tenantType === index ? `${styles.tenantTypeItem} ${styles.active}` : styles.tenantTypeItem
                      }
                      onClick={() => onChaneTenantType(index)}
                    >
                      <Space>
                        {i.icon}
                        <span>{i.label}</span>
                      </Space>
                    </div>
                  </Col>
                ))}
              </Row>

              <Row justify="end" style={{ marginTop: 24 }}>
                <Space>
                  <Button icon={<ArrowLeftOutlined />} onClick={() => setState((prev) => ({ ...prev, step: 1 }))}>
                    Trở về
                  </Button>
                  <Button
                    type="primary"
                    icon={<ArrowRightOutlined />}
                    onClick={() => setState((prev) => ({ ...prev, step: 3 }))}
                  >
                    Tiếp theo
                  </Button>
                </Space>
              </Row>
            </div>

            <div className={state.step === 3 ? '' : 'hidden'}>
              <Typography.Title level={2}>Chọn thêm dịch vụ phù hợp với công việc kinh doanh của bạn</Typography.Title>
              <div style={{ maxHeight: 400, overflowY: 'scroll', overflowX: 'hidden' }}>
                {serviceCategories.map((i) => {
                  const service = services.filter((e) => e.category === i.category);

                  return (
                    <div key={i.category}>
                      <Typography.Title level={5}>{i.label}</Typography.Title>
                      <Row gutter={6}>
                        {service.map((e) => {
                          const active = state.services.includes(e.service);
                          return (
                            <Col span={6} key={e.service}>
                              <div
                                className={active ? `${styles.serviceItem} ${styles.active}` : styles.serviceItem}
                                onClick={() => onCheckService(e, active)}
                              >
                                {e.label}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  );
                })}
              </div>

              <Row justify="end" style={{ marginTop: 24 }}>
                <Space>
                  <Button icon={<ArrowLeftOutlined />} onClick={() => setState((prev) => ({ ...prev, step: 2 }))}>
                    Trở về
                  </Button>
                  <Button
                    type="primary"
                    onClick={onSubmit}
                    icon={<CheckOutlined />}
                    loading={state.loading}
                    disabled={state.services.length === 0}
                  >
                    Hoàn tất
                  </Button>
                </Space>
              </Row>
            </div>
          </Col>
          <Col span={13} style={{ textAlign: 'center' }}>
            <img src="/image/logo.png" alt="logo" style={{ width: '60%' }} />
          </Col>
        </Row>
      </div>
    );
  }

  return props.children;
}

export default memo(RegisterTenantScreen);
