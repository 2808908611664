import {
  IAuth,
  IBaseResponse,
  ILoginBody,
  ILoginRes,
  ILogoutBody,
  IRefreshBody,
  IResetPasswordBody,
  IResetPasswordRequestBody,
} from 'src/types';
import { http } from 'src/utils';
import BaseService from './base.service';

export default class AuthService extends BaseService<IAuth> {
  constructor() {
    super('/auth');
  }

  public async login(body: ILoginBody) {
    const { data } = await http.post<IBaseResponse<ILoginRes>>(`${this.url}/login`, body);
    return data.data;
  }

  public async refresh(body: IRefreshBody) {
    const { data } = await http.post<IBaseResponse<ILoginRes>>(`${this.url}/refresh`, body);
    return data.data;
  }

  public async logout(body: ILogoutBody) {
    const { data } = await http.post<any>(`${this.url}/logout`, body);
    return data;
  }

  public async requestResetPassword(body: IResetPasswordRequestBody) {
    const { data } = await http.post<any>(`${this.url}/password/request`, body);
    return data;
  }

  public async resetPassword(body: IResetPasswordBody) {
    const { data } = await http.post<any>(`${this.url}/password/reset`, body);
    return data;
  }
}
