/* eslint-disable @typescript-eslint/no-unused-vars */
import { CloseOutlined, WechatWorkOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Card, Col, Dropdown, Input, Layout, Menu, Popover, Row, Space } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { memo, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ECollection, ItemService } from 'src/services';
import { useUser } from 'src/stores';
import { IParams, IUser, LayoutColor } from 'src/types';
import { IConversation } from 'src/types/conversation.type';
import { IConversationDirectusUsers } from 'src/types/conversation_directus_users.type';
import { IMessages } from 'src/types/messages.type';
import { FUNCS } from 'src/utils';
import ChatWindow from './compunent/ChatWindow';
import ListConversation from './compunent/ListConversation';
import ListUser from './compunent/ListUser';

const { Header, Content, Footer } = Layout;

interface IProps {
  logo: string;
  menus: ItemType<MenuItemType>[];
  accountItems: ItemType[];
  accountAvatar: string;
  sider?: JSX.Element | React.ReactNode;
  theme: LayoutColor;
  pathname: string;
  logoImage: string;
  dataMessageSocket: IMessages[];
  dataMessageCreate: IMessages[];
  dataConverstationSocket: IConversation[];
}

interface IState {
  editor: string;
  dataConversation: IConversation[];
  open: boolean;
  dataMessage: IMessages[];
  total: number;
  openChat: boolean;
  message: IMessages[];
  infoConversation: any;
  chatConversation: IConversation[];
  nameUserConversation: any;
  hasMore: boolean;
  page: number;
  isFocused: boolean;
  key_search: string;
}

const initState: IState = {
  editor: '',
  open: false,
  openChat: false,
  message: [],
  nameUserConversation: undefined,
  infoConversation: undefined,
  chatConversation: [],
  dataMessage: [],
  hasMore: false,
  page: 1,
  total: 0,
  isFocused: false,
  key_search: '',
  dataConversation: [],
};

const svMessage = new ItemService<IMessages>(ECollection.message);
const svDirectusUsers = new ItemService<IConversationDirectusUsers>(ECollection.conversation_directus_users);
const svConversation = new ItemService<IConversation>(ECollection.conversation);

function HorizontalLayout(props: IProps) {
  const [collapsed, setCollapsed] = useState(false);
  const { innerHeight: height } = window;
  const [state, setState] = useState<IState>(initState);
  const { user } = useUser();

  const rootPath = useMemo<string>(() => {
    return `/${props.pathname.split('/')[1]}`;
  }, [props.pathname]);

  const handleOpenChange = (newOpen: boolean) => {
    setState((prev) => ({ ...prev, open: newOpen }));
  };

  const fetchData = async (search?: string, page?: number) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const query: IParams<IMessages> = {
        limit: 15,
        page: 1,
        sort: ['-date_created'],
        fields: ['*,file.*'],
        search,
      };
      const res = await svMessage.list(query);
      const total = await svMessage.getTotal(query);
      setState((prev) => ({ ...prev, dataMessage: res.reverse(), total }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const fetchMoreData = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const query = {
        limit: 15,
        page: state.page + 1,
        sort: ['-date_created'],
        fields: ['*,file.*'],
        search: state.infoConversation.id,
      };
      const res = await svMessage.list(query);
      const total = await svMessage.getTotal(query);

      if (res.length === 0) {
        setState((prev) => ({ ...prev, hasMore: false, total }));
      } else {
        setState((prev) => ({
          ...prev,
          dataMessage: [...res.reverse(), ...prev.dataMessage],
          page: prev.page + 1,
        }));
      }
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const onRead = async () => {
    try {
      const ids = state.dataMessage.map((item) => item.id);
      const res = await svMessage.updateMulti(ids, {});
      console.log('res: ', res);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handleOpenChat = (messages: IMessages[], conversation: IConversation, user: any) => {
    fetchData(conversation.id);
    const isExists = state.chatConversation.some((item) => item.id === conversation.id);
    setState((prevState) => ({
      ...prevState,
      message: messages,
      infoConversation: conversation,
      openChat: true,
      open: false,
      nameUserConversation: user,
    }));
    if (!isExists) {
      setState((prevState) => ({
        ...prevState,
        message: messages,
        infoConversation: conversation,
        chatConversation: [...prevState.chatConversation.filter((item) => item.id !== conversation.id), conversation],
        openChat: true,
        open: false,
        nameUserConversation: user,
      }));
    }
  };

  const handleOpenChatUser = async (item: IUser) => {
    try {
      const query = {
        fields: ['*,user_created.id'],
        'filter[_and][0][directus_users_id][id][_eq]': item?.id.toString(),
        'filter[_and][1][conversation_id][users][directus_users_id][id][_eq]': user?.id,
      };
      const res = await svDirectusUsers.list(query);
      if (res.length > 0) {
        fetchData(res[0].conversation_id);
        const isExists = state.chatConversation.some((item) => item.id === res[0].conversation_id);
        setState((prevState) => ({
          ...prevState,
          // message: messages,
          // infoConversation: conversation,
          openChat: true,
          open: false,
          nameUserConversation: item,
        }));
        if (!isExists) {
          setState((prevState) => ({
            ...prevState,
            // message: messages,
            // infoConversation: conversation,
            // chatConversation: [
            //   ...prevState.chatConversation.filter((item) => item.id !== conversation.id),
            //   conversation,
            // ],
            openChat: true,
            open: false,
            nameUserConversation: item,
          }));
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    fetchDataConverSation();
  }, [props.dataMessageSocket, props.dataMessageCreate]);

  const fetchDataConverSation = async (search?: string) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const query: IParams<IConversation> = {
        limit: 20,
        sort: ['-date_last'],
        fields: [
          '*,user_created.*, messages.id,messages.content,messages.file.id, messages.date_created,messages.date_read,messages.user_created.id,user_id.id,user_id.first_name,user_id.last_name,user_id.avatar,users.directus_users_id.id,users.directus_users_id.avatar,users.directus_users_id.first_name,users.directus_users_id.last_name,*',
        ],
        deep: {
          messages: {
            _sort: '-date_created',
            _limit: '1',
          },
        },
      };
      const res = await svConversation.list(query);
      const total = await svConversation.getTotal(query);
      setState((prev) => ({ ...prev, dataConversation: res, total }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const toggleChatWindow = (id: string) => {
    setState((prev) => ({
      ...prev,
      chatConversation: state.chatConversation.filter((item) => item.id !== id),
      openChat: false,
    }));
  };

  const hidenChatWindow = () => {
    setState((prev) => ({
      ...prev,
      openChat: false,
    }));
  };

  const onDeleteConversation = (id: string) => {
    setState((prevState) => ({
      ...prevState,
      openChat: false,
      chatConversation: state.chatConversation.filter((item) => item.id !== id),
    }));
  };

  const handleFocus = () => {
    setState((prevState) => ({
      ...prevState,
      isFocused: true,
    }));
  };

  const handleBlur = () => {
    setState((prevState) => ({
      ...prevState,
      isFocused: false,
    }));
  };

  return (
    <Layout hasSider={false}>
      <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
          <img src={props.logoImage} alt="" width="40" />
          <h3
            style={{
              margin: '0px 12px 0px 6px',
              color: props.theme === LayoutColor.DARK ? 'white' : 'black',
            }}
          >
            {props.logo}
          </h3>
          <Menu
            theme={props.theme}
            mode="horizontal"
            selectedKeys={[rootPath]}
            items={props.menus}
            className="custom-menu"
          />
        </div>

        <Space>
          <Popover
            placement="bottomRight"
            title={<b style={{ fontSize: '18px' }}>Đoạn chat</b>}
            trigger="click"
            open={state.open}
            onOpenChange={handleOpenChange}
            content={
              <div className="custom-popover-content">
                <div className="search-user">
                  {state.isFocused ? (
                    <img
                      src="image/arrow.png"
                      style={{
                        marginBottom: '10px',
                        marginRight: '10px',
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                      alt="avatar"
                      onClick={handleBlur}
                    />
                  ) : (
                    ''
                  )}

                  <Input
                    className="search-ant"
                    size="large"
                    placeholder="Tìm kiếm người dùng"
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        key_search: e.target.value,
                      }))
                    }
                    onFocus={handleFocus}
                    // onBlur={handleBlur}
                    prefix={<img src="image/icons8-search-20.png" alt="User Icon" />}
                  />
                </div>

                {state.isFocused ? (
                  <ListUser keysearch={state.key_search} handleOpenChat={handleOpenChatUser} />
                ) : (
                  <ListConversation data={state.dataConversation} handleOpenChat={handleOpenChat} />
                )}
              </div>
            }
            arrow={false}
            style={{ height, overflow: 'scroll' }}
            overlayClassName="custom-popover"
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
              <Badge
                count={props.dataConverstationSocket.filter((item) => item.messages[0]?.date_read === null).length}
                offset={[-5, 5]}
                style={{
                  backgroundColor: '#ff4d4f',
                  color: '#fff',
                  fontSize: '12px',
                  padding: '0 6px',
                  borderRadius: '10px',
                  boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                }}
              >
                <WechatWorkOutlined
                  style={{ fontSize: '39px', color: props.theme === LayoutColor.DARK ? '#1890ff' : '' }}
                />
              </Badge>
            </div>
          </Popover>
          <Dropdown menu={{ items: props.accountItems }} placement="bottomRight">
            <Avatar size="large" src={<img src={props.accountAvatar} alt="avatar" />} style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Space>
      </Header>

      <Content hasSider={!!props.sider} style={{ padding: props.sider ? 10 : '10px 40px', height: height - 90 }}>
        <Layout>
          {props.sider ? (
            <Row gutter={20}>
              <Col span={5}>
                <Card bodyStyle={{ padding: 10 }}>{props.sider}</Card>
              </Col>
              <Col span={19}>
                <Outlet />
              </Col>
            </Row>
          ) : (
            <Outlet />
          )}
        </Layout>
      </Content>
      <div className="floating-button-container">
        {state.chatConversation.map((item) => {
          const user = item.users?.find((e) => e.directus_users_id.id !== item.user_created.id)?.directus_users_id;
          return (
            <div className="custom-button-wrapper" key={item.id}>
              <Button className="floating-button" onClick={() => handleOpenChat(item.messages, item, user)}>
                <Avatar src={FUNCS.getFullMedialUrl(user?.avatar)} className="avatar-in-button" />
              </Button>
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                className="custom-close-button"
                size="small"
                onClick={() => onDeleteConversation(item.id)}
              />
            </div>
          );
        })}

        {state.openChat && (
          <ChatWindow
            onClose={() => toggleChatWindow(state.infoConversation.id)}
            hidenChatWindow={hidenChatWindow}
            onLoadMore={fetchMoreData}
            hasMore={state.hasMore}
            messageTotal={state.total}
            messagePage={state.page}
            open={state.openChat}
            chat={state.dataMessage}
            info={state.infoConversation}
            nameUser={state.nameUserConversation}
            dataMessageSocket={props.dataMessageSocket}
            dataMessageCreate={props.dataMessageCreate}
          />
        )}
      </div>
      <Footer style={{ textAlign: 'center', padding: 0 }}>Business ©2024 Created by Teli</Footer>
    </Layout>
  );
}

export default memo(HorizontalLayout);
