import { IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IRolePolicy, IRolePolicyBody } from './role-policy.type';
import { ISettingRole, ISettingRoleBody } from './setting-role.type';
import { IUser, IUserBody } from './user.type';

export interface IRole extends IBaseEntity {
  description: string;
  icon: string;
  name: string;
  tenant_id: string;
  parent: IRole;
  children: IRole[];
  policies: IRolePolicy[];
  users: IUser[];
  setting_menus: ISettingRole[];
  level: ERoleLevel;
}

export interface IRoleBody {
  description?: string;
  icon?: string;
  name?: string;
  tenant_id?: string;
  parent?: string;
  children?: IRelationBody<IRoleBody>;
  policies?: IRelationBody<IRolePolicyBody>;
  users?: IRelationBody<IUserBody>;
  setting_menus?: IRelationBody<ISettingRoleBody>;
  level?: ERoleLevel;
}

export interface IRoleQuery extends IBaseQuery {}

export enum ERoleLevel {
  TENANT = 1,
  DEPARTMENT = 2,
}
