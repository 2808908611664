import { Input } from 'antd';
import React from 'react';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof Input.TextArea>, IBaseFormItemProps {}

export function BaseTextArea(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <Input.TextArea
        {...props}
        maxLength={props.maxLength || 1000}
        allowClear
        rows={props.rows || 4}
        placeholder={props.placeholder || props.label}
        showCount={props.showCount !== undefined ? props.showCount : true}
      />
    </BaseFormItem>
  );
}
