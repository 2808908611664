/* eslint-disable react/jsx-no-useless-fragment */
import { Skeleton } from 'antd';
import { useUser } from 'src/stores';
import { IConversation } from 'src/types/conversation.type';
import { IMessages } from 'src/types/messages.type';
import { FUNCS } from 'src/utils';
import TimeAgo from 'timeago-react';

interface IProps {
  data: IConversation[];
  handleOpenChat: (messages: IMessages[], conversation: IConversation, user: any) => void;
}

function ListConversation(props: IProps) {
  const { user } = useUser();
  return (
    <>
      {props.data.length > 0 ? (
        <div className="list-chat">
          {props.data.map((item, index) => {
            const isSeen = item?.messages[0]?.date_read === null;
            const isUser = item.users?.find((e) => e.directus_users_id.id !== item.user_created.id)?.directus_users_id;
            return (
              <div key={index} className="chat-item" onClick={() => props.handleOpenChat(item.messages, item, isUser)}>
                <div className="profile-pic">
                  <img src={FUNCS.getFullMedialUrl(isUser?.avatar)} alt={`${item.name}'s profile`} />
                </div>
                <div className="chat-details">
                  <div className="chat-name">{` ${isUser?.last_name} ${isUser?.first_name}`}</div>
                  <div
                    className="chat-message-converstation"
                    style={{
                      color: isSeen ? '#020' : '#b0b3b8',
                      fontWeight: isSeen ? 'bold' : '',
                    }}
                  >
                    {item.messages[0]?.user_created?.id === user?.id && 'Bạn: '}
                    {item.messages[0]?.file ? 'Hình ảnh' : item.messages[0]?.content || 'Chưa có tin nhắn'} ·
                    <TimeAgo datetime={item.date_last} locale="vi" />
                  </div>
                </div>
                <div className="chat-time">
                  {isSeen && (
                    <img
                      src="image/new-moon.png"
                      style={{
                        marginBottom: '7px',
                        marginTop: '7px',
                        marginRight: '10px',
                        width: '15px',
                        height: '15px',
                        cursor: 'pointer',
                      }}
                      alt="avatar"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Skeleton />
      )}
    </>
  );
}

export default ListConversation;
