/* eslint-disable prettier/prettier */
import { memo, useEffect, useState } from 'react';
import { SpinnerLoader } from 'src/libraries';
import { ECollection, ItemService, UserService } from 'src/services';
import { useDepartment, useRefresh, useTenant, useUser } from 'src/stores';
import { ERoleLevel, IBaseProps, IDepartment, ITenant } from 'src/types';

const svUser = new UserService();
const svTenant = new ItemService<ITenant>(ECollection.tenant);

function InitAppProvider({ children }: IBaseProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { changeUser } = useUser();
  const { changeTenant } = useTenant();
  const { changeDepartment } = useDepartment();
  const { refresh } = useRefresh();

  useEffect(() => {
    fetchInit();
  }, [refresh]);

  const fetchInit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const user = await svUser.me({
        fields: [
          '*',
          'role.*',
          'role.policies.policy.name',
          'departments.*',
          'departments.department_id.id',
          'departments.department_id.name',
          'departments.department_id.parents.*',
        ],
      });

      if (user) {
        // Lưu thông tin user vào redux
        changeUser(user);
        // Lấy thông tin tenant
        if (user.tenant_id) {
          const tenant = await svTenant.detail(user.tenant_id, {
            fields: [
              'id',
              'name',
              'layout',
              'theme',
              'color',
              'image',
              'settings.*',
              'settings.parents.*',
              'settings.children.*',
              'settings.services.*',
              'settings.services.service_id.name',
              'settings.services.service_id.id',
              'settings.service_id.name',
              'settings.service_id.id',
              'departments.id',
              'departments.name',
              'departments.parents.*',
              'social_shops.*',
            ],
            deep: {
              settings: {
                services: {
                  _sort: 'priority',
                },
                _sort: 'menu',
                _filter: {
                  _or: [
                    {
                      menu_roles: {
                        directus_roles_id: {
                          _eq: user.role?.id,
                        },
                      },
                    },
                    {
                      is_group_menu: true,
                    },
                  ],
                },
              },
            },
          });
          changeTenant(tenant);

          changeDepartment({
            departments:
              user.role.level === ERoleLevel.TENANT
                ? tenant.departments
                : user.departments.map((i) => i.department_id as IDepartment),
            myDepartments: user.departments.map((i) => i.department_id as IDepartment),
            allDepartments: tenant.departments,
          });

          localStorage.setItem('settings', JSON.stringify(tenant.settings));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  if (loading) return <SpinnerLoader bg={false} />;

  return children;
}

export default memo(InitAppProvider);
