import { IAttendanceSchedule } from './attendance-schedule.type';
import { IBaseEntity, IBaseQuery, ILocation } from './common.type';
import { IUser } from './user.type';

export interface IAttendance extends IBaseEntity {
  user_id: IUser;
  type: EAttendanceType;
  location: ILocation;
  images: string[];
  schedules: IAttendanceSchedule[];
}

export interface IAttendanceQuery extends IBaseQuery {
  start?: string;
  end?: string;
  type?: EAttendanceType;
  department_ids?: string[];
  schedule_ids?: string[];
}

export enum EAttendanceType {
  IN = 'IN',
  OUT = 'OUT',
}
