import Axios from 'axios';
import dayjs from 'dayjs';
import { AuthService } from 'src/services';
import { CONTANTS, EVENTS } from '.';

const isDEV = process.env.REACT_APP_ENVIRONMENT === 'development';
const REQ_TIMEOUT = 30 * 1000;

const http = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
  timeout: REQ_TIMEOUT,
  // withCredentials: true,
  maxBodyLength: Infinity,
});

let refreshingPromise: any = null;

const refreshToken = async () => {
  try {
    const refreshtoken = localStorage.getItem('refreshtoken');
    if (refreshtoken) {
      const svAuth = new AuthService();
      const res = await svAuth.refresh({ refresh_token: refreshtoken, mode: 'json' });
      localStorage.setItem('accesstoken', res.access_token);
      localStorage.setItem('refreshtoken', res.refresh_token);
      localStorage.setItem('expire', dayjs().add(res.expires, 'milliseconds').format(CONTANTS.DATE_TIME_SERVER));
    }
  } catch (error) {
    // MESSAGE.error('Phiên đăng nhập hết hạn!');
    // navigate(Routes.LOGIN.path);
    // localStorage.removeItem('accesstoken');
    // localStorage.removeItem('refreshtoken');
    // // xoá redux
    // changeUser(null);
    console.log(error);
  }
};

http.interceptors.request.use(async (request) => {
  if (isDEV) {
    if (request.url !== '/auth/refresh') {
      if (refreshingPromise) {
        await refreshingPromise;
      }

      let accesstoken = localStorage.getItem('accesstoken');
      const expire = localStorage.getItem('expire');

      if (dayjs(expire).diff(dayjs()) <= 0) {
        if (!refreshingPromise) {
          refreshingPromise = refreshToken();
        }
        await refreshingPromise;
        accesstoken = localStorage.getItem('accesstoken');
        refreshingPromise = null;
      }

      if (request.headers && accesstoken) {
        request.headers.Authorization = `Bearer ${accesstoken}`;
      }
    }
  }
  return request;
});

/**
 * intercepts any response and checks the response from our api for a 401 status in the response. ie. the token has now expired and is no longer valid, or no valid token was sent.
 * If such a status exists then we log out the user and clear the profile from redux state.
 */
http.interceptors.response.use(
  (res) => res,
  (err) => {
    EVENTS.onError(err);

    return Promise.reject(err);
  },
);

export default http;
