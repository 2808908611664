import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ReactNode, memo } from 'react';

interface IProps {
  title: ReactNode;
}

function GuideTooltip(props: IProps) {
  return (
    <Tooltip title={props.title}>
      <InfoCircleOutlined style={{ color: 'var(--colorWarning)', fontSize: 16 }} />
    </Tooltip>
  );
}

export default memo(GuideTooltip);
