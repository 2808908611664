import { App } from 'antd';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SpinnerLoader } from './libraries';
import { ConfigAntdProvider, ErrorProvider, MessageProvider, RouteProvider } from './providers';
import { persistor, store } from './stores';
import './styles/custom.scss';

// const LoginScreen = lazy(() => import('./screens/auth/login/index'));
// const ResetPasswordScreen = lazy(() => import('./screens/auth/reset-password'));
// const Page404Screen = lazy(() => import('./screens/errors/page404'));
// const Page500Screen = lazy(() => import('./screens/errors/page500'));
// const Privacy = lazy(() => import('./screens/privacy/privacy'));
// // const PageComingSoonScreen = lazy(() => import('./screens/errors/comingSoon'));

// const DashboardScreen = lazy(() => import('./screens/dashboard'));

function AppClient() {
  return (
    <Provider store={store}>
      <PersistGate loading={<SpinnerLoader bg={false} />} persistor={persistor}>
        <ConfigAntdProvider>
          <App>
            <MessageProvider>
              <ErrorProvider>
                <Suspense fallback={<SpinnerLoader bg={false} />}>
                  <RouteProvider />
                </Suspense>
              </ErrorProvider>
            </MessageProvider>
          </App>
        </ConfigAntdProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppClient;
