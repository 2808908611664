/* eslint-disable no-case-declarations */
import { AxiosError } from 'axios';
import { memo, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { Routes } from 'src/routes';
import { useUser } from 'src/stores';
import { EErorCode, HttpStatusCode, IBaseProps, IBaseResponse } from 'src/types';
import { MESSAGE } from 'src/utils';
import { EventBusName, IBaseEventPayload } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';

interface IProps extends IBaseProps {}

function ErrorProvider(props: IProps) {
  const subscriptions = useRef<any>();
  const navigate = useNavigate();
  const { changeUser } = useUser();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: IBaseEventPayload<AxiosError<any>>) => {
        switch (data.type) {
          case EventBusName.ERROR:
            const { response } = data.payload;
            const { data: res } = response as { status: HttpStatusCode; data: IBaseResponse<any> };

            if (res.errors) {
              const error = res.errors[0];
              if (error?.extensions?.code) {
                switch (error.extensions.code) {
                  case EErorCode.FORBIDDEN:
                    MESSAGE.error('Không có quyền truy cập chức năng này!');
                    break;
                  case EErorCode.INVALID_CREDENTIALS:
                    MESSAGE.error('Thông tin đăng nhập không chính xác!');
                    break;
                  case EErorCode.TOKEN_EXPIRED:
                    MESSAGE.error('Phiên đăng nhập hết hạn!');
                    navigate(Routes.LOGIN.path);
                    localStorage.removeItem('accesstoken');
                    localStorage.removeItem('refreshtoken');
                    // xoá redux
                    changeUser(null);
                    break;

                  default:
                    MESSAGE.error(error?.message || 'Lỗi hệ thống!');
                    break;
                }
              } else {
                MESSAGE.error(error?.message || 'Lỗi hệ thống!');
              }
            }

            break;
          default:
            break;
        }
      }),
    );
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  return props.children;
}

export default memo(ErrorProvider);
