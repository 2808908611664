import { IAddress, IAddressBody } from './address.type';
import { ICheckout } from './checkout.type';
import { IAggregateRes, IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IDepartmentUser, IDepartmentUserBody } from './department-user.type';
import { IRole } from './role.type';
import { IWage } from './wage.type';

export interface IUser extends IBaseEntity {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  location: null;
  title: string;
  description: string;
  // language: null;
  status: EUserStatus;
  last_access: string;
  last_page: string;
  provider: string;
  email_notifications: boolean;
  // payments: [];
  // wallets: [];
  checkouts: ICheckout[];
  // debts: [];
  // schedules: [];
  wages: IWage[];
  // wage_users: [];
  avatar: string;
  role: IRole;
  departments: IDepartmentUser[];
  total_wage: IAggregateRes<'value'>;
  total_checkout: IAggregateRes<'total_final'>;
  count_checkout: IAggregateRes<''>;
  count_product: IAggregateRes<'quantity'>;
  address: IAddress[];
  private: boolean;
  tenant_id: string;
  email_tiktok?: string;
}

export interface IUserQuery extends IBaseQuery {
  department_ids?: string[];
  role_ids?: string[];
  statuses?: EUserStatus[];
  tenant_id?: string;
  department_id?: string;
}

export enum EUserStatus {
  ACTIVE = 'active',
  UNVERIFIED = 'unverified',
  SUSPENDED = 'suspended',
  ARCHIVED = 'archived',
  INVITED = 'invited',
}

export interface IUserBody {
  first_name?: string;
  last_name?: string;
  avatar?: string;
  email?: string;
  password?: string;
  description?: string;
  status?: EUserStatus;
  role?: string;
  departments?: IRelationBody<IDepartmentUserBody>;
  address?: IRelationBody<IAddressBody>;
  private?: boolean;
  tenant_id?: { id?: string };
}

export interface ICreateUserBody extends IUserBody {}
export interface IUpdateUserBody extends IUserBody {}
