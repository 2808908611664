import { IBaseResponse, IParams, IShelf } from 'src/types';
import { http } from 'src/utils';
import BaseService from './base.service';

export default class ShelfService extends BaseService<IShelf> {
  constructor() {
    super('/items/shelf');
  }

  public async get(query: IParams) {
    const { data } = await http.get<IBaseResponse<IShelf[]>>(`${this.url}`, { params: query });
    return data.data;
  }

  public async createShelf(data: IShelf) {
    const response = await http.post<IBaseResponse<IShelf>>(`${this.url}`, data);
    return response.data.data;
  }

  public async updateShelf(data: IShelf, id: string) {
    const response = await http.patch<IBaseResponse<IShelf>>(`${this.url}/${id}`, data);
    return response.data.data;
  }

  public async deleteShelf(id: string) {
    const response = await http.delete<IBaseResponse<IShelf>>(`${this.url}/${id}`);
    return response.data.data;
  }
}
