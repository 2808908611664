import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UserService } from 'src/services';
import { useTenant, useUser } from 'src/stores';
import { IParams, IUser } from 'src/types';
import { FUNCS } from 'src/utils';

interface IProps {
  keysearch?: string;
  handleOpenChat: (user: IUser) => void;
}

interface IState {
  editor: string;
  open: boolean;
  total: number;
  data: IUser[];
}

const initState: IState = {
  editor: '',
  open: false,
  total: 0,
  data: [],
};

const svUser = new UserService();

function ListUser(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const { tenant } = useTenant();
  const { user } = useUser();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (search?: string) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const query: IParams<IUser> = {
        limit: 15,
        filter: {
          tenant_id: { _eq: tenant?.id },
          first_name: {
            _nnull: true,
          },
        },
        search,
      };
      const res = await svUser.list(query);
      const total = await svUser.getTotal(query);
      setState((prev) => ({ ...prev, data: res.filter((item) => item.id !== user?.id), total }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const debounceFn = _.debounce(fetchData, 800);
  useEffect(() => {
    if (props.keysearch) {
      debounceFn(props.keysearch);
    }
  }, [props.keysearch]);

  return (
    <div className="list-chat">
      {state.data.map((item) => (
        <div className="chat-item" onClick={() => props.handleOpenChat(item)}>
          <div className="profile-pic">
            <img src={FUNCS.getFullMedialUrl(item?.avatar)} alt={`${item?.first_name}'s profile`} />
          </div>
          <div className="chat-details">
            <div className="chat-name">{` ${item?.last_name} ${item?.first_name}`}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListUser;
