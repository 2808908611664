import { HomeOutlined, SettingOutlined } from '@ant-design/icons';

export interface IRoute {
  path: string;
  title: string;
  description?: string;
  permission?: string;
  icon?: any;
  children?: IRoute[];
}

export enum Route {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  VERIFICATION = 'VERIFICATION',
  RESET_PASSWORD = 'RESET_PASSWORD',
  HOME = 'HOME',
  PRIVACY = 'PRIVACY',
  PAGE500 = 'PAGE500',
  DOCUMENT = 'DOCUMENT',
  PROFILE = 'PROFILE',

  USER = 'USER',
  SETTING = 'SETTING',
  TENANT_REGISTER = 'TENANT_REGISTER',
}

export const Routes: { [key in Route]: IRoute } = {
  LOGIN: {
    path: '/dang-nhap',
    title: 'Đăng nhập',
  },
  REGISTER: {
    path: '/dang-ky',
    title: 'Đăng ký',
  },
  VERIFICATION: {
    path: '/xac-thuc-tai-khoan',
    title: 'Xác thực Email',
  },
  RESET_PASSWORD: {
    path: '/dat-lai-mat-khau',
    title: 'Đặt lại mật khẩu',
  },
  HOME: {
    path: '/trang-chu',
    title: 'Trang chủ',
    icon: <HomeOutlined />,
  },
  PRIVACY: {
    path: '/chinh-sach',
    title: 'Chính sách',
  },
  PAGE500: {
    path: '/loi',
    title: 'Lỗi hệ thống',
  },
  DOCUMENT: {
    path: '/tai-lieu',
    title: 'Tài liệu',
  },
  PROFILE: {
    path: '/thong-tin-ca-nhan',
    title: 'Thông tin cá nhân',
  },

  USER: {
    path: '/thanh-vien',
    title: 'Thành viên',
  },
  SETTING: {
    path: '/cai-dat',
    title: 'Cài đặt',
    icon: <SettingOutlined />,
  },
  TENANT_REGISTER: {
    path: '/dang-ky-co-so',
    title: 'Đăng ký loại hình doanh nghiệp cơ sở',
  },
};
