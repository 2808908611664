import { IBaseEntity, IBaseQuery, IFee, IRelationBody } from './common.type';
import { IDebt, IDebtBody } from './debt.type';
import { IDepartment } from './department.type';
import { IPack, IPackBody } from './pack.type';
import { IReturn } from './return.type';
import { IShelf } from './shelf.type';
import { ITenant } from './tenant.type';
import { IUser } from './user.type';

export interface ISession extends IBaseEntity {
  code: string;
  note?: string;
  from_tenant_id?: ITenant;
  from_user_id?: IUser;
  from?: string;
  tenant_id: string;
  department_id?: IDepartment;
  shelf_id?: IShelf;
  status: ESessionStatus;
  status_history: ISessionStatusHistory[];
  status_payment: ESessionStatusPayment;
  total: number;
  packs: IPack[];
  returns: IReturn[];
  debts: IDebt[];
  fees: IFee[];
}

export interface ISessionQuery extends IBaseQuery {
  start?: string;
  end?: string;
}

export interface ISessionBody {
  code?: string;
  note?: string;
  from_tenant_id?: string;
  from_user_id?: IUser;
  from?: string;
  tenant_id?: { id?: string };
  department_id?: { id?: string };
  shelf_id?: { id?: string };
  status?: ESessionStatus;
  status_history?: ISessionStatusHistory[];
  status_payment?: ESessionStatusPayment;
  total?: number;
  packs?: IRelationBody<IPackBody>;
  // returns?: IReturn[];
  debts?: IRelationBody<IDebtBody>;
  fees?: IFee[];
}

export enum ESessionStatus {
  CREATED = 1,
  APPROVED = 2,
  SHIPPING = 3,
  COMPLETE = 4,
  CANCEL = 5,
}

export interface ISessionStatusHistory {
  status: ESessionStatus;
  date: string;
}

export enum ESessionStatusPayment {
  NOT_PAID = 1,
  PARTIAL_PAID = 2,
  PAID = 3,
}
