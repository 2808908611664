import { IAggregateRes, IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IVoucherProduct, IVoucherProductBody } from './voucher-product.type';

export interface IVoucher extends IBaseEntity {
  type: EVoucherType;
  name: string;
  user_max: number;
  checkout_total: number;
  value: number;
  start: string;
  end: string;
  quantity: number;
  gifts: any[];
  products: IVoucherProduct[];
  voucher_entities: any[];
  tenant_id: string;

  // extends field
  count_entities?: IAggregateRes<''>;
  count_my_entities?: IAggregateRes<''>;
}

export enum EVoucherType {
  PERCENT = 'PERCENT',
  CURRENCY = 'CURRENCY',
  GIFT = 'GIFT',
}

export interface IVoucherQuery extends IBaseQuery {
  name?: string;
  type?: EVoucherType;
  start?: string;
  end?: string;
  tenant_id?: string;
  product_id?: string;
  product_category_id?: string;
  department_id?: string;
  user_id?: string;
  voucher_id?: string;
  product_ids?: string[];
  product_category_ids?: string[];
  department_ids?: string[];
  user_ids?: string[];
  voucher_ids?: string[];
  user_max?: number;
  checkout_total?: number;
  value?: number;
  quantity?: number;
  gifts?: any[];
  products?: IRelationBody<IVoucherProductBody>;
  voucher_entities?: any[];
}

export interface IVoucherBody extends IBaseEntity {
  type: EVoucherType;
  name: string;
  user_max: number;
  checkout_total: number;
  value: number;
  start: string;
  end: string;
  quantity: number;
  // gifts: any[];
  products: IRelationBody<IVoucherProductBody>;
  // voucher_entities: any[];
  // tenant_id: string;
}
