import { IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IDepartment } from './department.type';
import { IWageUser, IWageUserBody } from './wage_user.type';

export interface IWageTime extends IBaseEntity {
  name: string;
  value: number;
  type: IWageTimeType;
  include: IWageTimeInclude[];
  exclude: IWageTimeExclude[];
  department_id: IDepartment;
  tenant_id: string;
  wage_users: IWageUser[];
}

export enum IWageTimeType {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export interface IWageTimeInclude extends IBaseEntity {
  DAY_OF_MONTH: string;
  DAY_OF_WEEK: string;
  END: string;
  START: string;
}

export interface IWageTimeExclude extends IBaseEntity {
  DATE: string;
}
export interface IWageTimeQuery extends IBaseQuery {
  department_ids?: string[];
}

export interface IWageTimeBody {
  name?: string;
  value?: number;
  department_id?: { id: string };
  tenant_id?: { id: string };
  type: IWageTimeType;
  include?: IWageTimeInclude[];
  exclude?: IWageTimeExclude[];
  wage_users?: IRelationBody<IWageUserBody>;
}
