import { Checkbox, Col, Row } from 'antd';
import { memo, useState } from 'react';
import { BaseInput } from 'src/libraries';
import { ISetting } from 'src/types';
import { FUNCS } from 'src/utils';

interface IProps {
  onCheck: (checked: string[]) => void;
  cols: string[];
  setting: ISetting;
}

function SettingPopover(props: IProps) {
  const [search, setSearch] = useState<string>('');
  const onCheck = (checked: string[]) => {
    props.onCheck(checked);
  };

  return (
    <div style={{ maxWidth: '30vw' }}>
      <BaseInput
        placeholder="Tìm kiếm ..."
        noMessage
        span={24}
        showSearch
        onChange={(e) => setSearch(e.target.value || '')}
      />
      <Checkbox.Group style={{ width: '100%', marginTop: 6 }} onChange={onCheck} defaultValue={props.cols}>
        <Row>
          {(props.setting.fields || [])
            .filter(
              (i) =>
                FUNCS.removeDiacritics(i.label.toLocaleLowerCase()).search(
                  FUNCS.removeDiacritics(search.toLocaleLowerCase()),
                ) > -1,
            )
            .map((i) => (
              <Col key={i.field} span={12}>
                <Checkbox value={i.field}>{i.label}</Checkbox>
              </Col>
            ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
}

export default memo(SettingPopover);
