import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

const refreshSlice = createSlice<boolean, SliceCaseReducers<boolean>>({
  name: 'refresh',
  initialState: false,
  reducers: {
    setRefresh: (state, { payload }: PayloadAction<boolean>) => {
      // if (state) {
      //   return {
      //     ...state,
      //     ...payload,
      //   };
      // }
      return payload;
    },
  },
});

export const { setRefresh } = refreshSlice.actions;

export default refreshSlice.reducer;
