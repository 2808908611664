import { Button, Empty, Form, Row } from 'antd';
import { memo, useEffect, useState } from 'react';
import { BaseInput, BaseSelect } from 'src/libraries';
import PropertyInput, { IPropertyValue } from 'src/libraries/property-form/PropertyInput';
import { ECollection, ItemService } from 'src/services';
import { useTenant } from 'src/stores';
import {
  IBaseList,
  IDepartment,
  IParentChildBody,
  IProperty,
  IPropertyCategory,
  IPropertyCategorySetting,
  IPropertyDepartmentBody,
  ISetting,
  IUpdateDepartmentBody,
} from 'src/types';
import { MESSAGE } from 'src/utils';

interface IProps {
  info: ISetting;
  detail?: IDepartment;
}

const sv = new ItemService<IDepartment, {}, IUpdateDepartmentBody>(ECollection.department);
const svPropertyCategorySetting = new ItemService<IPropertyCategorySetting>(ECollection.property_category_setting);

interface IPropertyCategoryWithValue {
  propertyCategory: IPropertyCategory;
  values: IPropertyValue[];
  isRequired: boolean;
}

interface IState {
  departmentParentOpts: IBaseList<string>[];
  departmentChildOpts: IBaseList<string>[];
  propertyCategories: IPropertyCategorySetting[];
  propertyCategoriesWithValue: IPropertyCategoryWithValue[];
  loading: boolean;
}

const initState: IState = {
  departmentChildOpts: [],
  departmentParentOpts: [],
  propertyCategories: [],
  propertyCategoriesWithValue: [],
  loading: false,
};

function EditDepartmentScreen(props: IProps) {
  const [form] = Form.useForm();
  const [state, setState] = useState<IState>(initState);
  const { tenant } = useTenant();

  useEffect(() => {
    fetchDepartmentOptions();
    fetchPropertyCategorySetting();
  }, [props.info]);

  useEffect(() => {
    if (props.detail) {
      form.setFieldsValue({
        code: props.detail.code,
        name: props.detail.name,
        parent_ids: props.detail.parents.map((i) => i.parent_id),
        child_ids: props.detail.children.map((i) => i.child_id),
      });
    }
  }, [props.detail]);

  const fetchPropertyCategorySetting = async () => {
    // if (state.loading) return;
    // setState((prev) => ({ ...prev, loading: true }));
    try {
      const res = await svPropertyCategorySetting.list({
        fields: ['*', 'property_category_id.properties.*', 'property_category_id.*'],
        filter: {
          setting_id: { _eq: props.info.id },
        },
        sort: ['priority'],
      });
      setState((prev) => ({ ...prev, propertyCategories: res }));
    } catch (error) {
      console.log(error);
    }
    // setState((prev) => ({ ...prev, loading: false }));
  };

  const fetchDepartmentOptions = async () => {
    const settingIds: string[] = [
      ...props.info.children.map((i) => i.child_id),
      ...props.info.parents.map((i) => i.parent_id),
    ];

    try {
      const res = await sv.list({ fields: ['id', 'name', 'setting_id'], filter: { setting_id: { _in: settingIds } } });
      const departmentChildOpts: IBaseList<string>[] = [];
      const departmentParentOpts: IBaseList<string>[] = [];

      settingIds.forEach((i) => {
        const setting = tenant?.settings.find((e) => e.id === i);
        const opts = res
          .filter((e) => (e.setting_id as unknown as string) === i)
          .map((e) => ({ label: e.name, value: e.id }));

        const isParent = props.info.parents.findIndex((e) => e.parent_id === i) > -1;
        const isChild = props.info.children.findIndex((e) => e.child_id === i) > -1;
        if (setting && opts.length > 0) {
          if (isParent)
            departmentParentOpts.push({
              label: setting.label,
              options: opts,
            });
          if (isChild)
            departmentChildOpts.push({
              label: setting.label,
              options: opts,
            });
        }
      });

      setState((prev) => ({ ...prev, departmentChildOpts, departmentParentOpts }));
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeProperty = (propertyCategory: IPropertyCategory, values: IPropertyValue[], isRequired: boolean) => {
    setState((prev) => {
      const propertyCategoriesWithValue = prev.propertyCategoriesWithValue;
      if (propertyCategoriesWithValue.findIndex((i) => i.propertyCategory.id === propertyCategory.id) > -1) {
        return {
          ...prev,
          propertyCategoriesWithValue: propertyCategoriesWithValue.map((i) => {
            if (i.propertyCategory.id === propertyCategory.id) {
              return {
                ...i,
                values,
                isRequired,
              };
            }
            return i;
          }),
        };
      }
      return {
        ...prev,
        propertyCategoriesWithValue: [...propertyCategoriesWithValue, { propertyCategory, values, isRequired }],
      };
    });
  };

  const onFinish = async (values: any) => {
    if (!props.detail) return;
    const errors: string[] = [];
    const propertyBodyCreate: IPropertyDepartmentBody[] = [];
    const propertyBodyUpdate: IPropertyDepartmentBody[] = [];
    const propertyBodyDelete: number[] = [];
    state.propertyCategoriesWithValue.forEach((i) => {
      if (i.isRequired && i.values.length === 0) {
        errors.push(i.propertyCategory.name);
      }

      i.values.forEach((e) => {
        if (e.originId) {
          propertyBodyUpdate.push({
            id: e.originId,
            property_id: {
              value: e.value,
              label: e.label,
              image: e.image,
              icon: e.icon,
              id: e.id,
            },
          });
        } else if (e.id) {
          propertyBodyCreate.push({ property_id: { id: e.id } });
        } else {
          propertyBodyCreate.push({
            property_id: {
              property_category_id: i.propertyCategory.id,
              tenant_id: tenant?.id,
              value: e.value,
              label: e.label,
              image: e.image,
              icon: e.icon,
            },
          });
        }
      });
    });

    if (errors.length > 0) {
      MESSAGE.error(`Thuộc tính ${errors.join(', ')} không được để trống!`);
      return;
    }

    props.detail?.properties.forEach((i) => {
      if (propertyBodyUpdate.findIndex((e) => e.id === i.id) === -1) {
        propertyBodyDelete.push(i.id);
      }
    });

    const parentsBodyCreate: IParentChildBody[] = [];
    const parentsBodyDelete: string[] = [];
    const childrenBodyCreate: IParentChildBody[] = [];
    const childrenBodyDelete: string[] = [];

    const parentIds: string[] = values.parent_ids || [];

    parentIds.forEach((i) => {
      if (props.detail?.parents.findIndex((e) => e.parent_id === i) === -1) {
        parentsBodyCreate.push({ parent_id: i });
      }
    });

    props.detail?.parents.forEach((i) => {
      if (parentIds.findIndex((e) => e === i.parent_id) === -1) {
        parentsBodyDelete.push(i.id);
      }
    });

    const childrenIds: string[] = values.child_ids || [];

    childrenIds.forEach((i) => {
      if (props.detail?.children.findIndex((e) => e.child_id === i) === -1) {
        childrenBodyCreate.push({ child_id: i });
      }
    });

    props.detail?.children.forEach((i) => {
      if (childrenIds.findIndex((e) => e === i.child_id) === -1) {
        childrenBodyDelete.push(i.id);
      }
    });

    // submit
    if (state.loading) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      // call api
      await sv.update(props.detail.id, {
        name: values.name,
        code: values.code,
        setting_id: props.info.id,
        tenant_id: tenant?.id || '',
        parents: {
          create: parentsBodyCreate,
          update: [],
          delete: parentsBodyDelete,
        },
        children: {
          create: childrenBodyCreate,
          update: [],
          delete: childrenBodyDelete,
        },
        properties: {
          create: propertyBodyCreate,
          update: propertyBodyUpdate,
          delete: propertyBodyDelete,
        },
        // image?:
      });
      MESSAGE.success(`Cập nhật ${props.info.label} thành công!`);
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  if (!props.detail) return null;

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} style={{ padding: 16 }}>
      <Row justify="end">
        <Button htmlType="submit" loading={state.loading} type="primary">
          Lưu
        </Button>
      </Row>
      <Row gutter={24}>
        <BaseInput name="code" label="Mã" span={12} required />
        <BaseInput name="name" label="Tên" span={12} required />
      </Row>
      <div style={{ marginBottom: 20 }}>
        <Row justify="space-between">
          <p>Thuộc tính</p>
          {/* <PropertyCategoryPopover onCheckPropertyCategory={onCheckPropertyCategory} /> */}
        </Row>
        <Row gutter={24}>
          {state.propertyCategories.length > 0 ? (
            state.propertyCategories.map((i) => {
              const properties: IPropertyValue[] =
                props.detail?.properties
                  .filter((e) => (e.property_id as IProperty).property_category_id.id === i.property_category_id.id)
                  .map((e) => {
                    const property = e.property_id as IProperty;
                    return {
                      id: property.id,
                      label: property.label,
                      value: property.value,
                      image: property.image,
                      icon: property.icon,
                      originId: e.id,
                    };
                  }) || [];

              return (
                <PropertyInput
                  propertyCategory={i.property_category_id}
                  span={i.span}
                  required={i.required}
                  form={form}
                  key={i.id}
                  onChangeProperty={(values) => onChangeProperty(i.property_category_id, values, i.required)}
                  initValues={properties}
                />
              );
            })
          ) : (
            <Empty />
          )}
        </Row>
      </div>
      <Row gutter={24}>
        <BaseSelect name="parent_ids" label="Cấp trên" span={12} options={state.departmentParentOpts} mode="multiple" />
        <BaseSelect name="child_ids" label="Cấp dưới" span={12} options={state.departmentChildOpts} mode="multiple" />
      </Row>
    </Form>
  );
}

export default memo(EditDepartmentScreen);
