import { Select } from 'antd';
import React from 'react';
import { FUNCS } from 'src/utils';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof Select>, IBaseFormItemProps {}

export function BaseSelect(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <Select
        {...props}
        allowClear
        size={props.size || 'large'}
        placeholder={props.placeholder || props.label}
        filterOption={(input, option) => {
          console.log(option);

          if (!props.showSearch || props?.showSearch === undefined) return false;
          return FUNCS.removeDiacritics(option?.label as string)
            .toLowerCase()
            .includes(FUNCS.removeDiacritics(input?.toLowerCase()));
        }}
      />
    </BaseFormItem>
  );
}
