import { combineReducers } from 'redux';
import department from './department/department.slice';
import lang from './lang/lang.slice';
import refresh from './refresh/refresh.slice';
import tenant from './tenant/tenant.slice';
import user from './user/user.slice';

export default combineReducers({
  lang,
  user,
  tenant,
  department,
  refresh,
});
