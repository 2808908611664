import { Modal, Radio, Space } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { EAddType } from 'src/types';
import { EventBusName } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';

function ModalAddType() {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<EAddType>(EAddType.SELF);
  const [params, setParams] = useState<{ onOk: (type: EAddType) => Promise<boolean> }>();

  const subscriptions = useRef<any>();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: any) => {
        switch (data.type) {
          case EventBusName.SHOW_MODAL_ADD_TYPE:
            handleShowModal(data.payload);
            break;
          default:
            break;
        }
      }),
    );
  };

  const handleShowModal = (payload: { onOk: (type: EAddType) => Promise<boolean> }) => {
    setParams(payload);
    setVisible(true);
    setType(EAddType.SELF);
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  const handleOk = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await params?.onOk(type);
      if (res) {
        handleCancel();
      }
    } catch (error) {
      // merror(error.response.data);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setLoading(false);
    setParams(undefined);
  };

  return (
    <Modal title="Áp dụng cho" open={visible} zIndex={9999} onCancel={handleCancel} onOk={handleOk}>
      <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
        <Space direction="vertical">
          <Radio value={EAddType.SELF}>Với lịch hiện tại</Radio>
          <Radio value={EAddType.AFTER}>Với lịch sau lịch hiện tại</Radio>
          <Radio value={EAddType.ALL}>Với tất cả các lịch</Radio>
        </Space>
      </Radio.Group>
    </Modal>
  );
}

export default memo(ModalAddType);
