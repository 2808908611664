import {
  EAttendanceType,
  EExportParam,
  EProductAchievementType,
  EPropertyCategorySelectType,
  EPropertyCategoryType,
  EScheduleAchievementType,
  EScheduleConfigEndType,
  EScheduleConfigMode,
  EScheduleConfigRepeatType,
  EScheduleMode,
  ETaskRole,
  IBaseList,
  LayoutColor,
  LayoutType,
} from 'src/types';
import { IWageTimeType } from 'src/types/wage_time.type';

export default class OPTIONS {
  static genderOpts: IBaseList<number>[] = [
    { label: 'Nam', value: 1 },
    { label: 'Nữ', value: 2 },
    { label: 'Khác', value: 3 },
  ];

  static layoutOpts: IBaseList<LayoutType>[] = [
    { label: 'Dọc', value: LayoutType.VERTICAL },
    { label: 'Ngang', value: LayoutType.HORIZONTAL },
  ];

  static TypeNewsOpts: IBaseList<String>[] = [
    { label: 'Tin tức', value: 'NEW' },
    { label: 'Thông báo', value: 'NOTIFICATION' },
  ];

  static PaymentFilterTypeOpts: IBaseList<String>[] = [
    { label: 'Đơn hàng', value: 'CHECKOUT' },
    { label: 'Trả hàng', value: 'RETURN' },
    { label: 'Hoàn hàng', value: 'TURNBACK' },
    { label: 'Phiên', value: 'SESSION' },
  ];

  static EmotionTypeOpts: IBaseList<String>[] = [
    { label: 'Thích', value: 'LIKE' },
    { label: 'Yêu thích', value: 'LOVE' },
    { label: 'Haha', value: 'LAUGH' },
    { label: 'Wow', value: 'WOW' },
    { label: 'Buôn', value: 'SAD' },
    { label: 'Tức giận', value: 'ANGRY' },
  ];

  static TypeWageSellOpts: IBaseList<String>[] = [
    { label: 'Sản phẩm', value: 'PRODUCT' },
    { label: 'Doanh thu', value: 'INCOME' },
  ];

  static StatusWalletOpts: IBaseList<boolean>[] = [
    { label: 'Ví mặc định', value: true },
    { label: 'Chưa kích hoạt', value: false },
  ];

  static layoutColorOpts: IBaseList<LayoutColor>[] = [
    { label: 'Sáng', value: LayoutColor.LIGHT },
    { label: 'Tối', value: LayoutColor.DARK },
  ];

  static typeExportOpts: IBaseList<EExportParam>[] = [
    { label: 'CSV', value: EExportParam.csv },
    { label: 'JSON', value: EExportParam.json },
    { label: 'XML', value: EExportParam.xml },
    { label: 'YAML', value: EExportParam.yaml },
  ];

  static typePropertyCategoryOpts: IBaseList<EPropertyCategoryType>[] = [
    { label: 'Màu sắc', value: EPropertyCategoryType.COLOR },
    { label: 'Vị trí', value: EPropertyCategoryType.LOCATION },
    { label: 'Số', value: EPropertyCategoryType.NUMBER },
    { label: 'Chuỗi', value: EPropertyCategoryType.STRING },
    { label: 'Văn bản', value: EPropertyCategoryType.TEXT },
    { label: 'Thời gian', value: EPropertyCategoryType.TIME },
  ];

  static selectTypePropertyCategoryOpts: IBaseList<EPropertyCategorySelectType>[] = [
    { label: 'Đúng sai', value: EPropertyCategorySelectType.BOOL },
    { label: 'Lựa chọn thả xuống', value: EPropertyCategorySelectType.COMBO },
    { label: 'Nhập tự do', value: EPropertyCategorySelectType.FREE },
  ];

  static modeScheduleOpts: IBaseList<EScheduleMode>[] = [
    { label: 'Tập trung', value: EScheduleMode.OFFLINE },
    { label: 'Trực tuyến', value: EScheduleMode.ONLINE },
  ];

  static configModeScheduleOpts: IBaseList<EScheduleConfigMode>[] = [
    { label: 'Tức thì', value: EScheduleConfigMode.INSTANT },
    { label: 'Cố định', value: EScheduleConfigMode.ONCE },
    { label: 'Lặp lại', value: EScheduleConfigMode.REPEAT },
  ];

  static configRepeatTypeScheduleOpts: IBaseList<EScheduleConfigRepeatType>[] = [
    { label: 'Ngày', value: EScheduleConfigRepeatType.BY_DAY },
    { label: 'Tuần', value: EScheduleConfigRepeatType.BY_WEEK },
    { label: 'Tháng', value: EScheduleConfigRepeatType.BY_MONTH },
    { label: 'Tháng theo tuần', value: EScheduleConfigRepeatType.BY_MONTH_WEEK },
    { label: 'Năm', value: EScheduleConfigRepeatType.BY_YEAR },
    { label: 'Năm theo tuần', value: EScheduleConfigRepeatType.BY_YEAR_WEEK },
    { label: 'Năm theo tháng theo tuần', value: EScheduleConfigRepeatType.BY_YEAR_MONTH_WEEK },
    { label: 'Tuỳ chọn', value: EScheduleConfigRepeatType.CUSTOM },
  ];

  static configEndTypeScheduleOpts: IBaseList<EScheduleConfigEndType>[] = [
    { label: 'Ngày kết thúc', value: EScheduleConfigEndType.DATE },
    { label: 'Số lần', value: EScheduleConfigEndType.COUNT },
  ];

  static configModeTaskOpts: IBaseList<EScheduleConfigMode>[] = [
    // { label: 'Tức thì', value: EScheduleConfigMode.INSTANT },
    { label: 'Cố định', value: EScheduleConfigMode.ONCE },
    { label: 'Lặp lại', value: EScheduleConfigMode.REPEAT },
  ];

  static taskRoleOpts: IBaseList<ETaskRole>[] = [
    { label: 'Nhóm phó', value: ETaskRole.DEPUTY },
    { label: 'Thành viên', value: ETaskRole.MEMBER },
    { label: 'Nhóm trưởng', value: ETaskRole.MONITOR },
  ];

  static attendanceTypeOpts: IBaseList<EAttendanceType>[] = [
    { label: 'Đến', value: EAttendanceType.IN },
    { label: 'Về', value: EAttendanceType.OUT },
  ];

  static currencyOpts: IBaseList<string>[] = [
    { label: 'VND', value: 'VND' },
    { label: 'USD', value: 'USD' },
    { label: 'EURO', value: 'EURO' },
  ];

  static typeOpts: IBaseList<string>[] = [
    { label: 'Cá nhân', value: 'Individual' },
    { label: 'Cơ sở', value: 'Tenant' },
    { label: 'Phòng ban', value: 'Department' },
    { label: 'Người dùng', value: 'User' },
  ];

  static typeDebtOpts: IBaseList<string>[] = [
    { label: 'Cơ sở', value: 'Tenant' },
    { label: 'Người dùng', value: 'User' },
  ];

  static dayInWeekOpts: IBaseList<string>[] = [
    { label: 'Thứ Hai', value: '1' },
    { label: 'Thứ Ba', value: '2' },
    { label: 'Thứ Tư', value: '3' },
    { label: 'Thứ Năm', value: '4' },
    { label: 'Thứ Sáu', value: '5' },
    { label: 'Thứ Bảy', value: '6' },
    { label: 'Chủ Nhật', value: '0' },
  ];

  static typeSelectOpts: IBaseList<string>[] = [
    { label: 'Ngày trong tuần', value: 'DayInWeek' },
    { label: 'Ngày trong tháng', value: 'DayInMonth' },
  ];

  static selectAchievementOpts: IBaseList<string>[] = [
    { label: 'Sẳn phẩm', value: 'PRODUCT' },
    { label: 'Lịch', value: 'SCHEDULE' },
    { label: 'Nhiệm vụ', value: 'TASK' },
  ];

  static selectProductTypeOpts: IBaseList<string>[] = [
    { label: 'Số lượng', value: EProductAchievementType.QUANTITY },
    { label: 'Giá trị', value: EProductAchievementType.VALUE },
  ];

  static selectScheduleTypeOpts: IBaseList<string>[] = [
    { label: 'Có mặt', value: EScheduleAchievementType.ATTENDED },
    { label: 'Đúng, đủ giờ', value: EScheduleAchievementType.ONTIME },
  ];

  static TypeWageTimeOpts: IBaseList<IWageTimeType>[] = [
    { label: 'Phút', value: IWageTimeType.MINUTE },
    { label: 'Giờ', value: IWageTimeType.HOUR },
    { label: 'Ngày', value: IWageTimeType.DAY },
    { label: 'Tuần', value: IWageTimeType.WEEK },
    { label: 'Tháng', value: IWageTimeType.MONTH },
  ];

  static deliveryStatusOpts: IBaseList<string>[] = [
    { label: 'Hủy đơn hàng', value: '-1' },
    { label: 'Chưa tiếp nhận', value: '1' },
    { label: 'Đã tiếp nhận', value: '2' },
    { label: 'Đã lấy hàng/Đã nhập kho', value: '3' },
    { label: 'Đã lấy hàng/Đã nhập kho', value: '4' },
    { label: 'Đã giao hàng/Chưa đối soát', value: '5' },
    { label: 'Đã đối soát', value: '6' },
    { label: 'Không lấy được hàng', value: '7' },
    { label: 'Hoãn lấy hàng', value: '8' },
    { label: 'Không giao được hàng', value: '9' },
    { label: 'Delay giao hàng', value: '10' },
    { label: 'Đã đối soát công nợ trả hàng', value: '11' },
    { label: 'Đã điều phối lấy hàng/Đang lấy hàng', value: '12' },
    { label: 'Đơn hàng bồi hoàn', value: '13' },
    { label: 'Đang trả hàng', value: '20' },
    { label: 'Đã trả hàng', value: '21' },
    { label: 'Shipper báo đã lấy hàng', value: '123' },
    { label: 'Shipper báo không lấy được hàng', value: '127' },
    { label: 'Shipper báo delay lấy hàng', value: '128' },
    { label: 'Shipper báo đã giao hàng', value: '45' },
    { label: 'Shipper báo không giao được giao hàng', value: '49' },
    { label: 'Shipper báo delay giao hàng', value: '410' },
  ];
}
