export enum ECollection {
  department = 'department',
  finance_category = 'finance_category',
  wage_achievement = 'wage_achievement',
  field_view = 'field_view',
  property_category_setting = 'property_category_setting',
  property_category = 'property_category',
  property_department = 'property_department',
  property = 'property',
  service = 'service',
  setting_service = 'setting_service',
  setting = 'setting',
  tenant = 'tenant',
  schedule = 'schedule',
  shelf = 'shelf',
  shift = 'shift',
  news = 'news',
  subject = 'subject',
  task = 'task',
  debt = 'debt',
  unit = 'unit',
  rank = 'rank',
  comment = 'comment',
  emotion = 'emotion',
  achievement = 'achievement',
  achievement_user = 'achievement_user',
  achievement_rank = 'achievement_rank',
  voucher = 'voucher',
  sale = 'sale',
  product_category = 'product_category',
  task_schedule = 'task_schedule',
  task_role = 'task_role',
  payment = 'payment',
  attendance = 'attendance',
  wallet = 'wallet',
  product = 'product',
  wage_schedule = 'wage_schedule',
  wage_time = 'wage_time',
  wage_task = 'wage_task',
  wage_sell = 'wage_sell',
  wage = 'wage',
  session = 'session',
  voucher_product = 'voucher_product',
  product_entity = 'product_entity',
  return = 'return',
  product_property = 'product_property',
  sale_product = 'sale_product',
  product_entity_history = 'product_entity_history',
  conversation = 'conversation',
  message = 'message',
  checkout = 'checkout',
  address = 'address',
  nation = 'nation',
  province = 'province',
  district = 'district',
  ward = 'ward',
  turnback = 'turnback',
  conversation_directus_users = 'conversation_directus_users',
  department_directus_users = 'department_directus_users',
  delivery_brand = 'delivery_brand',
  social_shop_brand = 'social_shop_brand',
  social_shop = 'social_shop',
  social_product = 'social_product',
}
