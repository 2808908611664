import { createContext, memo, useContext, useEffect, useMemo, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ECollection } from 'src/services';
import { IBaseProps, IParams } from 'src/types';

const hostSocket =
  process.env.REACT_APP_WEBSOCKET_URL ||
  `wss://api-business.teli.vn/websocket?access_token=${localStorage.getItem('accesstoken')}`;

interface IWebSocket {
  onSubscribe?: (collection: ECollection, uid?: string, query?: IParams<any>) => void;
  message?: IMessage;
  conversation?: IConverstation;
  isAuth: boolean;
  onUnSubscribe?: (uid: string) => void;
}

interface IMessage {
  type: 'subscribe' | 'subscription' | 'auth' | 'ping' | 'pong';
  collection?: ECollection;
  event?: 'init' | 'create' | 'update' | 'delete';
  data?: any;
  query?: IParams<any>;
  uid?: string;
  status?: 'ok';
}

interface IConverstation {
  type: 'subscribe' | 'subscription' | 'auth' | 'ping' | 'pong';
  collection?: ECollection;
  event?: 'init' | 'create' | 'update' | 'delete';
  data?: any;
  query?: IParams<any>;
  uid?: string;
  status?: 'ok';
}

const defaultSocket: IWebSocket = { isAuth: false };

const SocketContext = createContext(defaultSocket);

export const useSocket = () => useContext(SocketContext);

function SocketProvider({ children }: IBaseProps) {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>();
  const [conversation, setConversation] = useState<IConverstation>();
  const webSocket = useWebSocket(hostSocket);

  useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.getWebSocket()?.close();
      }
    };
  }, []);

  useEffect(() => {
    const message = webSocket.lastJsonMessage as IMessage;

    if (!message) return;
    switch (message.type) {
      case 'auth':
        if (message.status === 'ok') {
          setIsAuth(true);
        }
        break;
      case 'ping':
        onPong();
        break;
      case 'subscription':
        setMessage(message);
        break;
      default:
        break;
    }
  }, [webSocket.lastJsonMessage]);

  useEffect(() => {
    const conversation = webSocket.lastJsonMessage as IConverstation;

    if (!conversation) return;
    switch (conversation.type) {
      case 'auth':
        if (conversation.status === 'ok') {
          setIsAuth(true);
        }
        break;
      case 'ping':
        onPong();
        break;
      case 'subscription':
        setConversation(conversation);
        break;
      default:
        break;
    }
  }, [webSocket.lastJsonMessage]);

  useEffect(() => {
    switch (webSocket.readyState) {
      case ReadyState.CONNECTING:
        console.log('Socket connecting!');
        break;
      case ReadyState.OPEN:
        console.log('Socket opened!');
        onAuthenticate();
        break;
      case ReadyState.CLOSING:
        console.log('Socket closing!');
        break;
      case ReadyState.UNINSTANTIATED:
        console.log('Socket uninstall!');
        break;
      case ReadyState.CLOSED:
        console.log('Socket closed!');
        setIsAuth(false);
        break;

      default:
        break;
    }
  }, [webSocket.readyState]);

  const onSubscribe = (collection: ECollection, uid?: string, query?: IParams<any>) => {
    if (!isAuth) {
      console.log('Chưa đăng nhập');
      return;
    }
    webSocket.sendJsonMessage({
      type: 'subscribe',
      collection,
      query,
      uid,
    });
  };

  const onUnSubscribe = (uid: string) => {
    webSocket.sendJsonMessage({
      type: 'unsubscribe',
      uid,
    });
  };

  const onAuthenticate = () => {
    webSocket.sendJsonMessage({
      type: 'auth',
      access_token: '9agMxmLJ4zI1q7NXVmpC46pLxDeyV061',
    });
  };

  const onPong = () => {
    webSocket.sendJsonMessage({
      type: 'pong',
    });
  };

  const context = useMemo<IWebSocket>(() => {
    return {
      onSubscribe,
      message,
      conversation,
      isAuth,
      onUnSubscribe,
    };
  }, [isAuth, conversation, message]);

  return <SocketContext.Provider value={context}>{children}</SocketContext.Provider>;
}

export default memo(SocketProvider);
