import { IBaseEntity, IBaseQuery, IFee, IRelationBody } from './common.type';
import { IDebt, IDebtBody } from './debt.type';
import { IReturnProduct, IReturnProductBody } from './return_product.type';
import { ISession } from './session.type';

export interface IReturn extends IBaseEntity {
  code: string;
  note?: string;
  status: EReturnStatus;
  status_history: IReturnStatusHistory[];
  status_payment: EReturnStatusPayment;
  total: number;
  debts: IDebt[];
  // checkout_id: ICheckout;
  session_id: ISession;
  return_products: IReturnProduct[];
  fees: IFee[];
}

export interface IReturnQuery extends IBaseQuery {
  code?: string;
}

export interface IReturnBody {
  code?: string;
  note?: string;
  status?: EReturnStatus;
  status_history?: IReturnStatusHistory[];
  status_payment?: EReturnStatusPayment;
  total?: number;
  debts?: IRelationBody<IDebtBody>;
  // checkout_id: ICheckout;
  session_id?: string;
  return_products?: IRelationBody<IReturnProductBody>;
  fees?: IFee[];
}

export enum EReturnStatus {
  CREATED = 1,
  APPROVED = 2,
  SHIPPING = 3,
  COMPLETE = 4,
  CANCEL = 5,
}

export interface IReturnStatusHistory {
  status: EReturnStatus;
  date: string;
}

export enum EReturnStatusPayment {
  NOT_PAID = 1,
  PARTIAL_PAID = 2,
  PAID = 3,
}
