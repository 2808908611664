import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { IDepartment } from 'src/types';
import { CONTANTS } from 'src/utils';

export const cols: any[] = [
  {
    key: 'name',
    render(_: any, record: IDepartment) {
      return <Link to={`${record.id}`}>{record.name}</Link>;
    },
    ellipsis: true,
    width: 600,
    className: 'dragable',
  },
  {
    key: 'date_created',
    render(_: any, record: IDepartment) {
      return <span>{dayjs(record.date_created).format(CONTANTS.DATE)}</span>;
    },
    ellipsis: true,
    width: 500,
    className: 'dragable',
  },
];
