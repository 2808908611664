import { DeleteOutlined, FileImageOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, FormInstance, Image, Row, Space, Tooltip } from 'antd';
import { memo, useRef, useState } from 'react';
import { FileService } from 'src/services';
import { useTenant } from 'src/stores';
import { IFlattenOptionData, IPropertyCategory } from 'src/types';
import { FUNCS } from 'src/utils';
import { BaseSelect } from '../form';
import { IPropertyValue } from './PropertyInput';
import { renderContent } from './renderContent';
import { renderInput } from './renderInput';

interface IProps {
  name: string;
  propertyCategory: IPropertyCategory;
  opts: IPropertyValue[];
  onAddProperty: (data: { image?: string; icon?: string }) => void;
  onDelete: (id: string) => void;
  form: FormInstance<any>;
}

const svFile = new FileService();

function PropertySelect(props: IProps) {
  const { tenant } = useTenant();
  const inputFile = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<{ image?: string; icon?: string }>({});

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;

    const file = e.target.files[0];
    try {
      const res = await svFile.upload(file, {});
      setState((prev) => ({ ...prev, image: res.id }));
    } catch (error) {
      console.log(error);
    }

    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };

  return (
    <BaseSelect
      name={props.name}
      span={24}
      placeholder={props.propertyCategory.name}
      noMessage
      size="small"
      options={props.opts}
      optionRender={(option: IFlattenOptionData<any>) => (
        <Row>
          <div style={{ flex: 1 }}>
            <Row align="middle">
              {option.data.image && (
                <Tooltip title="Ảnh thuộc tính">
                  <Image src={FUNCS.getFullMedialUrl(option.data.image)} width={24} height={24} preview={false} />
                </Tooltip>
              )}

              <div style={{ flex: 1, marginLeft: 8 }}>{renderContent(props.propertyCategory, option as any)}</div>
            </Row>
          </div>
          <Space style={{ marginLeft: 20 }}>
            {/* <Tooltip title="Chỉnh sửa">
              <EditOutlined className="property-btn" onClick={onEdit} />
            </Tooltip> */}

            <Tooltip title="Xoá">
              <DeleteOutlined
                className="property-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  props.onDelete(option.data.id);
                }}
              />
            </Tooltip>
          </Space>
        </Row>
      )}
      labelRender={(option) => {
        return renderContent(props.propertyCategory, option as any);
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Row style={{ padding: '0 8px 4px' }} align="middle">
            <div style={{ flex: 1 }}>
              <Row align="middle">
                <Tooltip title="Ảnh thuộc tính">
                  {state.image ? (
                    <Image
                      src={FUNCS.getFullMedialUrl(state.image)}
                      width={24}
                      height={24}
                      preview={false}
                      style={{ cursor: 'pointer' }}
                      onClick={() => inputFile.current?.click()}
                    />
                  ) : (
                    <FileImageOutlined
                      style={{ color: tenant?.color, cursor: 'pointer' }}
                      onClick={() => inputFile.current?.click()}
                    />
                  )}
                </Tooltip>
                <input
                  type="file"
                  id="upload"
                  onChange={(e) => onUpload(e)}
                  className="hidden"
                  accept="image/*"
                  ref={inputFile}
                />
                <div style={{ flex: 1, marginLeft: 8 }}>{renderInput(props.propertyCategory, props.form)}</div>
              </Row>
            </div>

            <Tooltip title="Lưu">
              <Button
                type="text"
                style={{ marginLeft: 20 }}
                icon={<PlusOutlined />}
                size="small"
                onClick={() => props.onAddProperty(state)}
              />
            </Tooltip>
          </Row>
        </>
      )}
    />
  );
}

export default memo(PropertySelect);
