import { ImportOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Space, Upload } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { IBaseImportDrawlerParams } from 'src/types';
import { EventBusName } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';

const { Dragger } = Upload;

function ImportDrawler() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IBaseImportDrawlerParams>();
  const [file, setFile] = useState<File>();

  const subscriptions = useRef<any>();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: any) => {
        switch (data.type) {
          case EventBusName.SHOW_DRAWLER_IMPORT:
            handleShowModal(data.payload);
            break;
          default:
            break;
        }
      }),
    );
  };

  const handleShowModal = (payload: IBaseImportDrawlerParams) => {
    setParams(payload);
    setVisible(true);
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  const handleOk = async () => {
    if (loading || !file) return;
    setLoading(true);
    try {
      const res = await params?.onOk(file);
      if (res) {
        handleCancel();
      }
    } catch (error) {
      // merror(error.response.data);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setLoading(false);
    setParams(undefined);
  };

  return (
    <Drawer
      title={
        <Space>
          <ImportOutlined />
          <span>Nhập {`${params?.title || 'nội dung'}`}</span>
        </Space>
      }
      placement="bottom"
      closable={false}
      onClose={handleCancel}
      open={!!params && visible}
      extra={
        <Button type="primary" size="large" icon={<UploadOutlined />} loading={loading} onClick={() => form.submit()}>
          Tải lên
        </Button>
      }
      height={300}
    >
      <Form form={form} onFinish={handleOk} style={{ padding: '0 200px' }} layout="vertical">
        <Dragger
          name="file"
          accept="application/json,.csv"
          maxCount={1}
          customRequest={(options) => {
            const { onSuccess, file } = options;
            setFile(file as File);
            onSuccess && onSuccess('Oke');
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Nhấp hoặc kéo tệp vào khu vực này để tải lên</p>
          <p className="ant-upload-hint">Hỗ trợ nhập file CSV và JSON.</p>
        </Dragger>
      </Form>
    </Drawer>
  );
}

export default memo(ImportDrawler);
