import { Col, Form, FormInstance, Row } from 'antd';
import { useEffect } from 'react';
import { FUNCS } from 'src/utils';
import { BaseInputNumber } from './BaseInputNumber';

interface IProps {
  form: FormInstance;
  span?: number;
  name?: string;
  label?: string;
  initValue?: number;
  onChange?: (duration: number) => void;
}

export function BaseDuration(props: IProps) {
  useEffect(() => {
    if (props.initValue) {
      const { days, hours, minutes, seconds } = FUNCS.convertDurationTime(props.initValue);

      props.form.setFieldsValue({
        [`${props.name || 'duration'}-day`]: days,
        [`${props.name || 'duration'}-hour`]: hours,
        [`${props.name || 'duration'}-minute`]: minutes,
        [`${props.name || 'duration'}-second`]: seconds,
      });
    } else {
      props.form.setFieldsValue({
        [`${props.name || 'duration'}-day`]: 0,
        [`${props.name || 'duration'}-hour`]: 0,
        [`${props.name || 'duration'}-minute`]: 0,
        [`${props.name || 'duration'}-second`]: 0,
      });
    }
  }, [props.initValue]);

  const onChange = () => {
    !!props.onChange &&
      props.onChange(
        FUNCS.convertToDuration({
          day: props.form.getFieldValue(`${props.name || 'duration'}-day`),
          hour: props.form.getFieldValue(`${props.name || 'duration'}-hour`),
          minute: props.form.getFieldValue(`${props.name || 'duration'}-minute`),
          second: props.form.getFieldValue(`${props.name || 'duration'}-second`),
        }),
      );
  };

  const onChangeHour = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = Number(e.target.value.replaceAll(',', ''));

    if (value && value >= 24) {
      const day = Math.floor(value / 24);
      const hour = value - day * 24;

      props.form.setFieldsValue({
        [`${props.name || 'duration'}-day`]: props.form.getFieldValue(`${props.name || 'duration'}-day`) + day,
        [`${props.name || 'duration'}-hour`]: hour,
      });
    }

    onChange();
  };

  const onChangeMinute = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = Number(e.target.value.replaceAll(',', ''));
    if (value && value >= 60) {
      const day = Math.floor(value / (24 * 60));
      const hour = Math.floor((value - day * (24 * 60)) / 60);
      const minute = Math.floor(value - day * (24 * 60) - hour * 60);

      let newHour = props.form.getFieldValue(`${props.name || 'duration'}-hour`) + hour;
      let dayAdd = 0;

      if (newHour >= 24) {
        dayAdd = Math.floor(newHour / 24);
        newHour %= 24;
      }

      props.form.setFieldsValue({
        [`${props.name || 'duration'}-day`]: props.form.getFieldValue(`${props.name || 'duration'}-day`) + day + dayAdd,
        [`${props.name || 'duration'}-hour`]: newHour,
        [`${props.name || 'duration'}-minute`]: minute,
      });
    }

    onChange();
  };

  const onChangeSecond = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = Number(e.target.value.replaceAll(',', ''));
    if (value && value >= 60) {
      const day = Math.floor(value / (24 * 60 * 60));
      const hour = Math.floor((value - day * (24 * 60 * 60)) / (60 * 60));
      const minute = Math.floor((value - day * (24 * 60 * 60) - hour * 60) / 60);
      const second = Math.floor(value - day * (24 * 60 * 60) - hour * 60 - minute * 60);

      let newMinute = props.form.getFieldValue(`${props.name || 'duration'}-minute`) + minute;
      let hourAdd = 0;
      let dayAdd = 0;

      if (newMinute >= 60) {
        hourAdd = Math.floor(newMinute / 60);
        newMinute %= 60;
      }

      let newHour = props.form.getFieldValue(`${props.name || 'duration'}-hour`) + hour + hourAdd;
      if (newHour >= 24) {
        dayAdd = Math.floor(newHour / 24);
        newHour %= 24;
      }

      props.form.setFieldsValue({
        [`${props.name || 'duration'}-day`]: props.form.getFieldValue(`${props.name || 'duration'}-day`) + day + dayAdd,
        [`${props.name || 'duration'}-hour`]: newHour,
        [`${props.name || 'duration'}-minute`]: newMinute,
        [`${props.name || 'duration'}-second`]: second,
      });
    }

    onChange();
  };

  return (
    <Col span={props.span || 6}>
      <Form.Item label={props.label} style={{ marginBottom: 0 }}>
        <Row gutter={4}>
          <BaseInputNumber name={`${props.name || 'duration'}-day`} suffix="ngày" noMessage onBlur={onChange} />
          <BaseInputNumber
            name={`${props.name || 'duration'}-hour`}
            min={0}
            suffix="giờ"
            onBlur={onChangeHour}
            noMessage
          />
          <BaseInputNumber
            name={`${props.name || 'duration'}-minute`}
            min={0}
            suffix="phút"
            onBlur={onChangeMinute}
            noMessage
          />
          <BaseInputNumber
            name={`${props.name || 'duration'}-second`}
            min={0}
            suffix="giây"
            onBlur={onChangeSecond}
            noMessage
          />
        </Row>
      </Form.Item>
    </Col>
  );
}
