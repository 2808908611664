/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unused-prop-types */
import { Image } from 'antd';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useEffect, useState } from 'react';
import { BaseUploadHidden } from 'src/libraries';
import BaseInfinityScroll from 'src/libraries/BaseInfinityScroll';
import { ECollection, FileService, ItemService } from 'src/services';
import { useUser } from 'src/stores';
import { IFile, IUser } from 'src/types';
import { IMessages } from 'src/types/messages.type';
import { FUNCS } from 'src/utils';

dayjs.extend(dayOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface IProps {
  nameUser: IUser;
  info?: any;
  chat: IMessages[];
  open: boolean;
  hasMore: boolean;
  messageTotal: number;
  dataMessageSocket: IMessages[];
  dataMessageCreate: IMessages[];
  messagePage: number;
  onClose: (id: any) => void;
  onLoadMore: () => void;
  hidenChatWindow: () => void;
}

interface IState {
  files: IFile[];
  loadingUpload: boolean;
}

const initState: IState = {
  loadingUpload: false,
  files: [],
};

const svMessage = new ItemService<IMessages>(ECollection.message);
const svFile = new FileService();

function ChatWindow(props: IProps) {
  const [messages, setMessages] = useState<IMessages[]>([]);
  const [input, setInput] = useState('');
  const [state, setState] = useState<IState>(initState);

  const { user } = useUser();

  const checkDiffTime = (index: number) => {
    if (!messages[index + 1]) return true;
    const dateCurrentMessage = dayjs(messages[index].date_created).dayOfYear();
    const datePreviousCurrentMessage = dayjs(messages[index + 1].date_created).dayOfYear();

    if (dateCurrentMessage !== datePreviousCurrentMessage) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setMessages(props.chat);
  }, [props.chat]);

  useEffect(() => {
    if (props.dataMessageCreate.length > 0) {
      setMessages((prevMessages) => [...prevMessages, props.dataMessageCreate[0]]);
    }
  }, [props.dataMessageCreate]);

  useEffect(() => {
    messages.forEach((item: any) => {
      const file = state.files.find((file) => file.id === item.file);
      if (file) {
        item.file = file;
      }
    });
  }, [messages]);

  const handleSend = async () => {
    try {
      if (input === '') {
        console.log('sss');
      } else {
        const body: any = {
          content: input,
          conversation_id: props.info.id,
        };
        const res = await svMessage.create(body);
        setInput('');
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    if (state.loadingUpload) return;
    setState((prev) => ({ ...prev, loadingUpload: true }));
    const filesAdd: IFile[] = [];
    try {
      await Promise.all(
        files.map(async (i) => {
          const res = await svFile.upload(i, {});
          const body: any = {
            file: res.id,
            conversation_id: props.info.id,
          };
          const resMessage = await svMessage.create(body);
          filesAdd.push(res);
        }),
      );
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loadingUpload: false, files: [...prev.files, ...filesAdd] }));
  };

  return (
    <div className="chat-window">
      <div className="chat-header">
        <div style={{ color: 'white', fontWeight: 'bold' }}>
          {props.info ? ` ${props.nameUser?.last_name} ${props.nameUser?.first_name}` : ''}
        </div>
        <div>
          <img
            src="image/minus-sign.png"
            style={{
              marginBottom: '7px',
              marginTop: '7px',
              marginRight: '14px',
              width: '15px',
              height: '15px',
              cursor: 'pointer',
            }}
            alt="avatar"
            onClick={props.hidenChatWindow}
          />
          <img
            src="image/close.png"
            style={{
              marginBottom: '7px',
              marginTop: '7px',
              marginRight: '10px',
              width: '15px',
              height: '15px',
              cursor: 'pointer',
            }}
            alt="avatar"
            onClick={() => props.onClose(props.info.id)}
          />
        </div>
      </div>

      <div
        id="scrollableDiv"
        style={{
          height: '400px',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <BaseInfinityScroll
          dataLength={messages.length}
          customStyle={{
            overflowX: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          hasMore={messages.length < props.messageTotal}
          currentPage={props.messagePage}
          inverse
          scrollableTarget="scrollableDiv"
          fetchMoreData={props.onLoadMore}
          haveLoading={props.messagePage !== 1}
        >
          <div className="chat-body">
            {messages.map((msg: any, index: number) => {
              const isUserSend = msg?.user_created === user?.id;
              const isDiffDate = checkDiffTime(index);
              const isLastMessage = index === messages.length - 1 && isUserSend;
              return (
                <div>
                  {isDiffDate && (
                    <div className="diffDate">
                      <p>
                        {dayjs(new Date()).dayOfYear() === dayjs(msg.date_created).dayOfYear()
                          ? ''
                          : dayjs(msg.date_created).format('DD/MM/YYYY')}
                      </p>
                    </div>
                  )}
                  <div key={msg.id}>
                    {msg.file ? (
                      <Image
                        style={{ padding: '7px' }}
                        width={170}
                        src={FUNCS.getFullMedialUrl(msg.file.filename_disk)}
                      />
                    ) : (
                      <div>
                        <div key={index} className={`message-content ${isUserSend ? 'sent' : 'received'}`}>
                          <div className={`message ${isUserSend ? 'sent' : 'received'}`}>{msg.content}</div>
                          {isLastMessage && (
                            <span className="status">
                              Đã gửi{'  '}
                              {isDiffDate && (
                                <p style={{ marginLeft: '3px' }}>
                                  {dayjs(new Date()).dayOfYear() === dayjs(msg.date_created).dayOfYear()
                                    ? dayjs(msg.date_created).format('HH:mm')
                                    : ''}
                                </p>
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </BaseInfinityScroll>
      </div>
      <div className="chat-footer">
        <BaseUploadHidden onUpload={onUpload}>
          <img src="image/svg/image.svg" style={{ marginRight: '10px', width: '28px', height: '28px' }} alt="avatar" />
        </BaseUploadHidden>
        <input
          className="custom-input"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSend();
          }}
          placeholder=""
        />
        <img onClick={handleSend} src="image/svg/send.svg" style={{ width: '28px', height: '28px' }} alt="avatar" />
      </div>
    </div>
  );
}

export default ChatWindow;
