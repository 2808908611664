import { EAddType, IBaseConfirmModalParams, IBaseExportDrawlerParams, IBaseImportDrawlerParams } from 'src/types';
import EventBus from './event-bus';
import { EventBusName, IBaseEventPayload } from './event-bus.types';

export default class EVENT {
  static onShowModalConfirm(params: IBaseConfirmModalParams) {
    EventBus.getInstance().post<IBaseEventPayload<any>>({
      type: EventBusName.SHOW_MODAL_CONFIRM,
      payload: params,
    });
  }

  static onShowModalAddType(params: { onOk: (type: EAddType) => Promise<boolean> }) {
    EventBus.getInstance().post<IBaseEventPayload<any>>({
      type: EventBusName.SHOW_MODAL_ADD_TYPE,
      payload: params,
    });
  }

  static onShowExportDrawler(params: IBaseExportDrawlerParams) {
    EventBus.getInstance().post<IBaseEventPayload<any>>({
      type: EventBusName.SHOW_DRAWLER_EXPORT,
      payload: params,
    });
  }

  static onShowImportDrawler(params: IBaseImportDrawlerParams) {
    EventBus.getInstance().post<IBaseEventPayload<any>>({
      type: EventBusName.SHOW_DRAWLER_IMPORT,
      payload: params,
    });
  }

  static onError(params: any) {
    EventBus.getInstance().post<IBaseEventPayload<any>>({
      type: EventBusName.ERROR,
      payload: params,
    });
  }
}
