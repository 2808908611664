import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { I18n } from 'i18n-js';
import en from 'src/lang/en.json';
import vi from 'src/lang/vi.json';

const i18n = new I18n();
i18n.store(en);
i18n.store(vi);
i18n.defaultLocale = 'vi';
i18n.locale = 'vi';

const initialState: { locale: string; lang: I18n } = {
  locale: 'vi',
  lang: i18n,
};

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLang: (state, { payload }: PayloadAction<{ locale: string; lang?: I18n } | undefined>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { setLang } = langSlice.actions;

export default langSlice.reducer;
