import { DownloadOutlined, ExportOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Row, Space } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { EExportParam, IBaseExportDrawlerParams } from 'src/types';
import { CONTANTS, OPTS } from 'src/utils';
import { EventBusName } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';
import { BaseInputNumber, BaseSelect } from '../form';

function ExportDrawler() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IBaseExportDrawlerParams>();

  const subscriptions = useRef<any>();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: any) => {
        switch (data.type) {
          case EventBusName.SHOW_DRAWLER_EXPORT:
            handleShowModal(data.payload);
            break;
          default:
            break;
        }
      }),
    );
  };

  const handleShowModal = (payload: IBaseExportDrawlerParams) => {
    setParams(payload);
    setVisible(true);
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  const handleOk = async (values: { type: EExportParam; count?: number }) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await params?.onOk(values);
      if (res) {
        handleCancel();
      }
    } catch (error) {
      // merror(error.response.data);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setLoading(false);
    setParams(undefined);
  };

  return (
    <Drawer
      title={
        <Space>
          <ExportOutlined />
          <span>Xuất {`${params?.title || 'nội dung'}`}</span>
        </Space>
      }
      placement="bottom"
      closable={false}
      onClose={handleCancel}
      open={!!params && visible}
      extra={
        <Button type="primary" size="large" icon={<DownloadOutlined />} loading={loading} onClick={() => form.submit()}>
          Tải xuống
        </Button>
      }
      height={220}
    >
      <Form form={form} onFinish={handleOk} layout="vertical">
        <Row gutter={24}>
          <BaseSelect name="type" label="Định dạng" span={12} required options={OPTS.typeExportOpts} />
          <BaseInputNumber
            name="count"
            label="Số bản ghi"
            span={12}
            defaultValue={CONTANTS.PAGE_SIZE}
            placeholder="Không giới hạn"
          />
        </Row>
      </Form>
    </Drawer>
  );
}

export default memo(ExportDrawler);
