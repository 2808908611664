import { DeleteOutlined, DownloadOutlined, MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Popover, Space, Tooltip } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import _ from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BasePaginate, BaseTable, Container, FormSearch } from 'src/libraries';
import { ECollection, ItemService } from 'src/services';
import { useUser } from 'src/stores';
import {
  EExportParam,
  IBaseState,
  ICreateFieldViewBody,
  IDepartment,
  IDepartmentQuery,
  IField,
  IFieldBody,
  IFieldView,
  IParams,
  ISetting,
  IUpdateFieldViewBody,
} from 'src/types';
import { CONTANTS, EVENTS, FUNCS, MESSAGE } from 'src/utils';
import SettingPopover from '../../components/SettingPopover';
import { cols } from './columns';
import FilterDrawler from './components/FilterDrawler';

interface IProps {
  info: ISetting;
}

interface IState extends IBaseState<IDepartment> {
  userFields: IField[];
  editFields: IFieldBody[];
  fieldViewId?: string;
  loadedField?: boolean;
}

const initState: IState = {
  loading: false,
  total: 0,
  data: [],
  refresh: false,
  detail: undefined,
  selected: [],
  page: 1,
  filterDrawler: false,
  countFilter: 0,
  userFields: [],
  editFields: [],
};

const sv = new ItemService<IDepartment>(ECollection.department);
const svFieldView = new ItemService<IFieldView, ICreateFieldViewBody, IUpdateFieldViewBody>(ECollection.field_view);

function DepartmentScreen(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const [params, setParams] = useState<IDepartmentQuery>({
    limit: CONTANTS.PAGE_SIZE,
    page: 1,
  });
  const { pathname } = useLocation();
  const { user } = useUser();

  useEffect(() => {
    fetchData();
  }, [params, state.refresh]);

  useEffect(() => {
    setParams({
      limit: CONTANTS.PAGE_SIZE,
      page: 1,
    });
    setState(initState);
    fetchFieldView();
  }, [props.info.id]);

  useEffect(() => {
    if (state.loadedField) {
      upsertFieldView();
    }
  }, [state.editFields]);

  const fetchData = async (search?: string) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const query: IParams<IDepartment> = {
        limit: params.limit,
        page: params.page,
        filter: {
          setting_id: { _eq: props.info.id },
          parents: { _some: { parent_id: { _in: params.department_parent_ids } } },
        },
        fields: ['id', 'name', 'date_created'],
        search,
      };
      const res = await sv.list(query);
      const total = await sv.getTotal(query);
      setState((prev) => ({ ...prev, data: res, total }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const fetchFieldView = async () => {
    try {
      const res = await svFieldView.list({
        fields: ['*'],
        filter: {
          setting_id: { _eq: props.info.id },
          user_id: { _eq: user?.id },
        },
      });
      if (res) {
        const fields = [...(res[0]?.fields || props.info.fields.filter((i) => i.show))].sort(
          (a, b) => a.priority - b.priority,
        );
        setState((prev) => ({
          ...prev,
          userFields: fields,
          editFields: fields,
          fieldViewId: res[0]?.id,
          loadedField: true,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const upsertFieldView = async () => {
    try {
      if (state.fieldViewId) {
        // cập nhật
        await svFieldView.update(state.fieldViewId, {
          fields: state.editFields.map((i, index) => ({ ...i, priority: index + 1 })),
        });
      } else {
        // Thêm mới
        await svFieldView.create({
          fields: state.editFields.map((i, index) => ({ ...i, priority: index + 1 })),
          user_id: user?.id || '',
          setting_id: props.info.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = (page: number): void => {
    setParams({ ...params, page });
  };

  // thay đổi bản ghi
  const onRecordChange = (limit: number): void => {
    setParams({ ...params, page: 1, limit });
  };

  const debounceFn = _.debounce(fetchData, 800);

  const onSearch = (value: string) => {
    debounceFn(value);
  };

  const onClearSearch = () => {
    setState((prev) => ({ ...prev, countFilter: 0 }));
    setParams({
      page: params.page,
      limit: params.limit,
    });
  };

  const onFilter = (values: any) => {
    let count = 0;

    Object.keys(values).forEach((i) => {
      if (values[i]) {
        if (Array.isArray(values[i])) {
          if (values[i].length > 0) count += 1;
        } else {
          count += 1;
        }
      }
    });

    setParams((prev) => ({
      ...prev,
      department_parent_ids: values.department_parent_ids
        ? _.uniq((values.department_parent_ids as string[]).map((i) => i.split('.')[1]))
        : [],
    }));

    setState((prev) => ({ ...prev, countFilter: count, filterDrawler: false }));
  };

  const onConfirmDelete = (item: IDepartment) => {
    EVENTS.onShowModalConfirm({
      title: `Xóa ${props.info.label}`,
      content: `Bạn chắc chắn muốn xóa ${item.name} này?`,
      onOk: () => onDelete(item),
    });
  };

  const onDelete = async (item: IDepartment) => {
    if (!item) return false;
    try {
      await sv.delete(item.id);
      MESSAGE.success(`Xóa ${props.info.label} thành công!`);
      setState((prev) => ({ ...prev, refresh: !prev.refresh }));
      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  const onConfirmDeleteMulti = () => {
    EVENTS.onShowModalConfirm({
      title: `Xóa ${props.info.label}`,
      content: `Bạn chắc chắn muốn xóa những ${props.info.label.toLocaleLowerCase()} đã chọn này?`,
      onOk: () => onDeleteMulti(state.selected),
    });
  };

  const onDeleteMulti = async (items: IDepartment[]) => {
    if (!items.length) return false;
    try {
      await sv.deleteMulti(items.map((i) => i.id));
      MESSAGE.success(`Xóa ${props.info.label} thành công!`);
      setState((prev) => ({ ...prev, refresh: !prev.refresh, selected: [] }));
      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  const onDragField = (fields: IFieldBody[]) => {
    setState((prev) => ({ ...prev, editFields: fields }));
  };

  const onResizeField = (field: string, width: number) => {
    setState((prev) => ({
      ...prev,
      editFields: prev.editFields.map((i) => {
        if (field === i.field) {
          return { ...i, width };
        }
        return i;
      }),
    }));
  };

  const onCheckFields = (fields: string[]) => {
    setState((prev) => {
      const fieldsPrev = prev.editFields.map((i) => i.field);

      const fieldAdd = _.difference(fields, fieldsPrev);
      const fieldRemove = _.difference(fieldsPrev, fields);
      if (fieldAdd.length > 0) {
        const field = prev.userFields.find((i) => i.field === fieldAdd[0]);

        if (field) {
          return {
            ...prev,
            editFields: [...prev.editFields, { field: field.field, width: field.width }],
          };
        }
        const fieldSetting = props.info.fields.find((i) => i.field === fieldAdd[0]);
        if (fieldSetting) {
          return {
            ...prev,
            editFields: [...prev.editFields, { field: fieldSetting.field, width: fieldSetting.width }],
            userFields: [
              ...prev.userFields,
              {
                field: fieldSetting.field,
                width: fieldSetting.width,
                label: fieldSetting.label,
                priority: prev.userFields.length + 1,
              },
            ],
          };
        }
      }

      if (fieldRemove.length > 0) {
        return {
          ...prev,
          editFields: prev.editFields.filter((i) => i.field !== fieldRemove[0]),
        };
      }

      return prev;
    });
  };

  const onExport = () => {
    EVENTS.onShowExportDrawler({
      title: props.info.label.toLocaleLowerCase(),
      onOk: (values) => onExportDepartment(values),
    });
  };

  const onImport = () => {
    EVENTS.onShowImportDrawler({
      title: props.info.label.toLocaleLowerCase(),
      onOk: (values) => onImportDepartment(values),
    });
  };

  const onExportDepartment = async (values: { type: EExportParam; count?: number | undefined }) => {
    try {
      const query: IParams<IDepartment> = {
        limit: values.count,
        page: params.page,
        filter: {
          setting_id: { _eq: props.info.id },
          parents: { _some: { parent_id: { _in: params.department_parent_ids } } },
        },
        fields: ['id', 'name', 'date_created', 'setting_id'],
        export: values.type,
        // search,
      };
      const res = await sv.export(query);
      MESSAGE.success('Xuất file thành công!');
      if (res) {
        const data = values.type === EExportParam.json ? JSON.stringify(res) : res;
        FUNCS.download(data, `Danh sách ${props.info.label.toLocaleLowerCase()}.${values.type}`, values.type);
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const onImportDepartment = async (file: File) => {
    try {
      await sv.import(file);
      MESSAGE.success(`Nhập ${props.info.label.toLocaleLowerCase()} thành công!`);
      setState((prev) => ({ ...prev, refresh: !prev.refresh }));

      return true;
    } catch (error) {
      console.log();
      return false;
    }
  };

  const colsFlex = useMemo<any[]>(() => {
    const result: any[] = [];

    state.userFields.forEach((i) => {
      const col = cols.find((e) => e.key === i.field);
      const title = props.info.fields.find((e) => e.field === i.field)?.label;
      if (col) {
        result.push({ ...col, className: 'dragable', width: i.width, title });
      }
    });

    return result;
  }, [state.userFields]);

  const columns = useMemo<any[]>(() => {
    return [
      {
        title: (
          <Popover
            placement="bottomLeft"
            content={
              <SettingPopover
                onCheck={onCheckFields}
                cols={state.userFields.map((i) => i.field)}
                setting={props.info}
              />
            }
            trigger="click"
          >
            <Tooltip title="Cài đặt hiển thị" placement="top">
              <MoreOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Tooltip>
          </Popover>
        ),
        key: 'action',
        render(_: any, record: IDepartment) {
          const items: MenuProps['items'] = [
            { key: 'delete', label: 'Xóa', onClick: () => onConfirmDelete(record), icon: <DeleteOutlined /> },
          ];
          return (
            <Dropdown menu={{ items }} placement="bottomLeft">
              <MoreOutlined style={{ fontSize: 20, cursor: 'pointer' }} />
            </Dropdown>
          );
        },
        align: 'center',
        width: 50,
        isFix: true,
      },
      {
        title: 'STT',
        key: 'stt',
        render(_: any, record: IDepartment, index: number) {
          return <span>{(params.limit || CONTANTS.PAGE_SIZE) * ((params.page || 1) - 1) + index + 1}</span>;
        },
        ellipsis: true,
        align: 'center',
        width: 50,
        isFix: true,
      },
      ...colsFlex,
    ];
  }, [params.limit, params.page, pathname, colsFlex]);

  const rowSelection: TableRowSelection<IDepartment> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows) => {
      setState((prev) => ({ ...prev, selected: selectedRows }));
    },
  };

  if (!props.info) return null;

  return (
    <Container
      title={props.info.label}
      // breadcrumbs={[{ title: props.info.label }]}
      renderRight={
        <Space>
          {state.selected?.length > 0 && (
            <Tooltip title="Xóa" placement="bottom">
              <Button size="large" type="primary" danger icon={<DeleteOutlined />} onClick={onConfirmDeleteMulti} />
            </Tooltip>
          )}
          <FormSearch
            onSearch={onSearch}
            onRefresh={onClearSearch}
            onFilter={() => setState((prev) => ({ ...prev, filterDrawler: true }))}
            countFilter={state.countFilter}
            setting={props.info}
          />
          <Button.Group>
            <Tooltip title="Xuất" placement="bottom">
              <Button size="large" type="primary" onClick={onExport} icon={<DownloadOutlined />} />
            </Tooltip>
            <Tooltip title="Nhập" placement="bottom">
              <Button size="large" type="primary" onClick={onImport} icon={<UploadOutlined />} />
            </Tooltip>
          </Button.Group>
          <Link to={`${pathname}/them-moi`}>
            <Button size="large" type="primary" icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Link>
        </Space>
      }
    >
      <BaseTable
        data={state.data}
        loading={state.loading}
        columns={columns}
        rowSelection={rowSelection}
        onDragField={onDragField}
        onResizeField={onResizeField}
        colsShow={state.editFields.map((i) => i.field || '')}
      />
      <BasePaginate
        page={params.page}
        take={params.limit || CONTANTS.PAGE_SIZE}
        total={state.total}
        onPageChange={onPageChange}
        onRecordChange={onRecordChange}
        isFixed
      />
      <FilterDrawler
        onClose={() => setState((prev) => ({ ...prev, filterDrawler: false }))}
        open={state.filterDrawler}
        setting={props.info}
        onFilter={onFilter}
      />
    </Container>
  );
}

export default memo(DepartmentScreen);
