import { ConfigProvider, ThemeConfig } from 'antd';
import viVN from 'antd/locale/vi_VN';
import { memo, useMemo } from 'react';
import { useTenant } from 'src/stores';
import { IBaseProps, LayoutColor } from 'src/types';

function ConfigAntdProvider({ children }: IBaseProps) {
  const { tenant, colorLight, colorNormal } = useTenant();

  const theme = useMemo<ThemeConfig>(() => {
    return {
      token: {
        fontFamily: tenant?.font || 'Lexend',
        colorPrimary: colorNormal,
      },
      components: {
        Layout: {
          headerBg: tenant?.theme === LayoutColor.DARK ? '#001529' : 'white',
          bodyBg: 'white',
          footerBg: 'white',
        },
        Table: {
          headerBg: colorLight,
          headerBorderRadius: 16,
        },
        Button: {
          borderRadius: 10,
          borderRadiusLG: 12,
        },
        Input: {
          colorBgContainer: colorLight,
          colorBorder: colorLight,
          borderRadius: 12,
          borderRadiusOuter: 12,
          borderRadiusLG: 12,
        },
        Select: {
          colorBgContainer: colorLight,
          colorBorder: colorLight,
          borderRadius: 12,
          borderRadiusOuter: 12,
          borderRadiusLG: 12,
        },
        InputNumber: {
          colorBgContainer: colorLight,
          colorBorder: colorLight,
          borderRadius: 12,
          borderRadiusOuter: 12,
          borderRadiusLG: 12,
        },
        DatePicker: {
          colorBgContainer: colorLight,
          colorBorder: colorLight,
          borderRadiusOuter: 12,
          borderRadiusLG: 12,
          borderRadius: 12,
        },

        Tabs: {
          colorText: '#8e8e8e',
          controlItemBgActive: 'red',
          verticalItemPadding: '8px 18px',
          verticalItemMargin: '0px',
        },
        // Badge: {
        //   colorBgBase: color.colorBg,
        //   colorBgContainer: color.colorBg,
        // },
        Radio: {
          buttonBg: colorLight,
          // colorBorder: 'transparent',
          borderRadius: 12,
        },
        Tree: {
          titleHeight: 32,
        },
      },
    };
  }, [tenant]);

  return (
    <ConfigProvider locale={viVN} theme={theme}>
      {children}
    </ConfigProvider>
  );
}

export default memo(ConfigAntdProvider);
