/* eslint-disable no-case-declarations */
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { memo, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ExportDrawler, IconFont, ImportDrawler, ModalConfirm } from 'src/libraries';
import { useSocket } from 'src/providers/socket.provider';
import { RouteMenus, Routes } from 'src/routes';
import { AuthService, ECollection } from 'src/services';
import { useTenant, useUser } from 'src/stores';
import { IBaseState, ISetting, LayoutType } from 'src/types';
import { IConversation } from 'src/types/conversation.type';
import { IMessages } from 'src/types/messages.type';
import { FUNCS } from 'src/utils';
import HorizontalLayout from './horizontal.layout';
import VerticalLayout from './vertical.layout';

interface IState extends IBaseState<IConversation> {
  fieldViewId?: string;
  loadedField?: boolean;
  dataMessageSocket: IMessages[];
  dataMessageCreate: IMessages[];
  dataConverstation: IConversation[];
}

const initState: IState = {
  loading: false,
  total: 0,
  data: [],
  refresh: false,
  detail: undefined,
  selected: [],
  page: 1,
  filterDrawler: false,
  countFilter: 0,
  dataMessageSocket: [],
  dataMessageCreate: [],
  dataConverstation: [],
};

const sv = new AuthService();

function LayoutClient() {
  const { user, changeUser } = useUser();
  const { pathname } = useLocation();
  const { tenant, changeTenant } = useTenant();
  const navigate = useNavigate();
  const { message, conversation, isAuth, onSubscribe, onUnSubscribe } = useSocket();
  const [state, setState] = useState<IState>(initState);

  useEffect(() => {
    return () => {
      onUnSubscribe && onUnSubscribe('uuid_message');
    };
  }, []);

  useEffect(() => {
    onSubscribe &&
      isAuth &&
      onSubscribe(ECollection.conversation, 'uuid_converstation', {
        limit: 20,
        sort: ['-date_last'],
        fields: [
          '*,user_created.*, messages.id,messages.content,messages.file.id, messages.date_created,messages.date_read,messages.user_created.id,user_id.id,user_id.first_name,user_id.last_name,user_id.avatar,users.directus_users_id.id,users.directus_users_id.avatar,users.directus_users_id.first_name,users.directus_users_id.last_name,*',
        ],
        deep: {
          messages: {
            _sort: '-date_created',
            _limit: '1',
          },
        },
      });
  }, [isAuth]);

  useEffect(() => {
    onSubscribe &&
      isAuth &&
      onSubscribe(ECollection.message, 'uuid_message', {
        sort: ['-date_created'],
        fields: ['*', 'conversation_id.messages.*'],
      });
  }, [isAuth]);

  useEffect(() => {
    if (message?.uid !== 'uuid_message') return;
    switch (message?.event) {
      case 'init':
        setState((prev) => ({ ...prev, dataMessageSocket: message.data }));
        break;
      case 'create':
        setState((prev) => ({
          ...prev,
          dataMessageCreate: message.data,
        }));
        break;
      case 'delete':
        // setCount((prev) => prev - 1);
        break;
      case 'update':
        setState((prev) => ({
          ...prev,
          refresh: !state.refresh,
        }));
        break;

      default:
        break;
    }
  }, [message]);

  useEffect(() => {
    if (conversation?.uid !== 'uuid_converstation') return;
    switch (conversation?.event) {
      case 'init':
        const filteredData = conversation.data.filter((item: any) =>
          item.users.some((i: any) => i.directus_users_id.id === user?.id),
        );
        setState((prev) => ({ ...prev, dataConverstation: filteredData }));
        break;
      case 'create':
        break;
      case 'delete':
        break;
      case 'update':
        setState((prev) => ({
          ...prev,
          refresh: !state.refresh,
        }));
        break;

      default:
        break;
    }
  }, [conversation]);

  useEffect(() => {
    Array.from(document.getElementsByClassName('ant-menu-submenu-title')).forEach((i) => {
      i.classList.remove('ant-menu-item-selected');
      // @ts-ignore
      const dataSet = i?.dataset?.menuId?.split('-');
      if (dataSet) {
        if (dataSet[dataSet.length - 1] === `/${pathname.split('/')[1]}`) {
          i.classList.add('ant-menu-item-selected');
        }
      }
    });
  }, [pathname]);

  const handleLogout = async () => {
    try {
      const refresh_token = localStorage.getItem('refreshtoken');
      refresh_token && (await sv.logout({ refresh_token }));
      changeUser(null);
      changeTenant(null);

      localStorage.removeItem('accesstoken');
      localStorage.removeItem('refreshtoken');

      navigate(Routes.LOGIN.path);
    } catch (err) {
      console.log(err);
    }
  };
  const avartarDropdownItems = useMemo<any>(() => {
    return [
      {
        label: <p onClick={() => navigate(Routes.PROFILE.path)}>Thông tin cá nhân</p>,
        key: 'profile',
      },
      {
        label: <p onClick={handleLogout}>Đăng xuất</p>,
        key: 'logout',
      },
    ];
  }, []);

  const getMenuTree = (settings: ISetting[], parent?: string): ItemType<MenuItemType>[] => {
    return settings
      .filter((i) => {
        if (parent) {
          const arr = i.menu?.split('.') || [];
          arr.pop();
          return arr.join('.') === parent;
        }
        return i.menu?.split('.').length === 1;
      })
      .map((i) => {
        const path = FUNCS.renderPath(i.label);
        const children = getMenuTree(settings, i.menu);

        return {
          key: `/${path}`,
          label: i.is_group_menu ? (
            i.label
          ) : (
            <Link
              style={{ color: 'inherit', display: !parent ? 'block' : undefined }}
              to={path}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {i.label}
            </Link>
          ),
          icon: <IconFont type={`icon-${i.icon}`} />,
          children: children.length > 0 ? children : undefined,
          groupMenu: i.is_group_menu,
        };
      });
  };

  const menus = useMemo<ItemType<MenuItemType>[]>(() => {
    if (!tenant?.settings) return [];
    const filterRoutes = tenant.settings.filter((i) => {
      // check permission
      return i && true;
    });

    return [
      ...RouteMenus.filter(
        (i) =>
          i.path !== Routes.SETTING.path ||
          (user?.role?.policies.some((i) => i.policy.name === 'tenant editor') && i.path === Routes.SETTING.path),
      ).map((i) => ({
        key: i.path,
        label: <Link to={i.path}>{i.title}</Link>,
        icon: i.icon,
      })),
      // @ts-ignore
      ...getMenuTree(filterRoutes, undefined).filter((i) => !i.groupMenu || (i.children?.length || 0) !== 0),
    ];
  }, [tenant]);

  if (!user) return null;

  if (!tenant) return <Outlet />;
  return (
    <>
      {tenant.layout === LayoutType.VERTICAL ? (
        <VerticalLayout
          logoImage={FUNCS.getFullMedialUrl(tenant.image)}
          logo={tenant.name}
          accountAvatar={FUNCS.getFullMedialUrl(user?.avatar)}
          menus={menus}
          accountItems={avartarDropdownItems}
          theme={tenant.theme}
          pathname={pathname}
          dataMessageSocket={state.dataMessageSocket}
          dataMessageCreate={state.dataMessageCreate}
          dataConverstationSocket={state.dataConverstation}
        />
      ) : (
        <HorizontalLayout
          logoImage={FUNCS.getFullMedialUrl(tenant.image)}
          logo={tenant.name}
          accountAvatar={FUNCS.getFullMedialUrl(user?.avatar)}
          menus={menus}
          accountItems={avartarDropdownItems}
          theme={tenant.theme}
          pathname={pathname}
          dataMessageSocket={state.dataMessageSocket}
          dataMessageCreate={state.dataMessageCreate}
          dataConverstationSocket={state.dataConverstation}
        />
      )}
      <ModalConfirm />
      <ExportDrawler />
      <ImportDrawler />
    </>
  );
}

export default memo(LayoutClient);
