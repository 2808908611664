import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { IDepartment } from 'src/types';
import { FUNCS } from 'src/utils';
import { IDepartmentSlice, setDepartment } from './department.slice';

export default function useDepartment() {
  const dispatch = useDispatch();
  const department = useAppSelector((state) => state.department);

  const changeDepartment = (payload: IDepartmentSlice) => {
    dispatch(setDepartment(payload));
  };

  return {
    changeDepartment,
    departments: department.departments,
    myDepartments: department.myDepartments,
    allDepartments: department.allDepartments,
    departmentTree: FUNCS.getTreeDataMTM<IDepartment>(department.departments),
  };
}
