/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBaseResponse } from 'src/types';
import { http } from 'src/utils';
import BaseService from './base.service';
import { ECollection } from './collection';

export default class ItemService<T, C = {}, U = {}> extends BaseService<T, C, U> {
  collection: ECollection;

  constructor(collection: ECollection) {
    super(`items/${collection}`);
    this.collection = collection;
  }

  public async import(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    await http.post<IBaseResponse<string>>(`utils/import/${this.collection}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 1000 * 60 * 3,
    });
  }
}
